import React, {useEffect, useState} from 'react'
import CustomDropdown from '../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomInput from '../../../../customComponents/customInputs/customInput/CustomInput'
import Show from '../../../../customComponents/show/Show'
import CustomButton2, {
    BUTTON_SIZE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton2'
import useValidation from '../../../../hooks/useValidation'
import {newBillingDetailsValidation} from '../../../../validations/validations'
import {
    postBillingFrequencyDropdownListService,
    postTenantBillingDetailsService,
} from '../../../../services/Services'
import CustomToast from '../../../../customComponents/customToast/CustomToast'
import {getErrorMessageFromResponse} from '../../../../helpers/CommonHelpers'
import CustomRadioButton1 from '../../../../customComponents/customRadioButton/CustomRadioButton1'
import CustomLoader from '../../../../customComponents/customLoader/CustomLoader'
import './styles.css'
import {useNavigate} from 'react-router-dom'

const BillingDetails = ({
    workTypeFirst,
    workTypeContractors,
    tabActive,
    tenantId,
    billingDetailsData,
    setBillingDetailsData,
    pidOrWorkerView,
    setPidOrWorkerView,
    completeStep,
    location,
    loadingGetData,
    selectedButton,
}) => {
    const naviagte = useNavigate()

    const [billingFrequencyDropdown, setBillingFrequencyDropdown] = useState([])
    // const [switchIsChecked, setSwitchIsChecked] = useState(false)
    const [validateSaleData, salesErrorData] = useValidation()
    const [loading, setLoading] = useState(false)

    const updateBillingDetailsData = (e) => {
        setBillingDetailsData(() => ({
            ...billingDetailsData,
            [e?.target?.name]: e?.target?.value,
        }))
    }

    // const onChangeSwitch = useCallback((e) => {
    //     setSwitchIsChecked((prevState) => !prevState)
    // }, [])

    useEffect(() => {
        postBillingFrequencyDropdownListService().then((res) => {
            const dropdownOptions = res?.data?.map((item) => ({
                name: item?.name,
                value: item?.id,
            }))
            setBillingFrequencyDropdown(dropdownOptions)
        })
    }, [])
    const body = {
        tenant_id: location?.state?.tid || tenantId,
        frequency_type_id: billingDetailsData?.frequencyTypeId,
        min_monthly_pay: billingDetailsData?.minimumMonthlyFee,
        base_fee: pidOrWorkerView,
        fee_for_processing_pid:
            pidOrWorkerView === 'per_pid_and_m2'
                ? billingDetailsData?.baseFreeForProcessingPID
                : '',
        fee_per_pid_and_m2:
            pidOrWorkerView === 'per_pid_and_m2' ? billingDetailsData?.basefreePerPIDandM2 : '',
        fee_per_user_per_month:
            pidOrWorkerView === 'per_user_per_month'
                ? billingDetailsData?.baseFreeForProcessingPID
                : '',

        // fee_for_processing_pid: billingDetailsData?.baseFreeForProcessingPID,
        // fee_per_pid_and_m2: billingDetailsData?.basefreePerPIDandM2,
        // fee_per_user_per_month: billingDetailsData?.baseFreeForProcessingPID,
        // sequifi_pay_status: switchIsChecked ? 1 : 0,
        fee_per_check_for_1099_workers: billingDetailsData?.workTypeFreeForProcessingPID,
        onetime_payment_process_fee_via_sequifi: billingDetailsData?.workTypeViaSequif,
        fee_per_employee_per_month: billingDetailsData?.workTypeFreeForEmployeePerMonth,
        overtime_rate_per_hour: billingDetailsData?.workTypeOverTimeRatePerHour,
    }

    useEffect(() => {
        if (salesErrorData?.beginValidating) {
            validateSaleData(
                newBillingDetailsValidation(
                    body,
                    pidOrWorkerView,
                    workTypeFirst,
                    workTypeContractors
                )
            )
        }
    }, [billingDetailsData, pidOrWorkerView, workTypeFirst, workTypeContractors])
    const saveAndContinue = () => {
        validateSaleData(
            newBillingDetailsValidation(body, pidOrWorkerView, workTypeFirst, workTypeContractors)
        ).then((res) => {
            if (res.isValidate) {
                // if (type == 'alert') {
                setLoading(true)
                postTenantBillingDetailsService(body)
                    .then((res) => {
                        // onTabChange(2)
                        // if (location?.state?.tid) {
                        //     naviagte('multitenant-dashboard')
                        // }
                        naviagte(-1)
                        completeStep()
                        CustomToast.success(res?.message)
                        const formData = {
                            currentStep: 0,
                            tenantId: '',
                        }
                        localStorage.setItem('currentStep', JSON.stringify(formData))
                    })
                    .catch((e) => CustomToast.error(getErrorMessageFromResponse(e)))
                    .finally(() => setLoading(false))

                // }
            }
        })
    }
    return (
        <div style={{position: 'relative'}}>
            <CustomLoader visible={loading} full />
            <div className='max-w-xl'>
                <div className='text-xl-bold text-sqGray900'>Billing Details</div>
                <div>
                    <div className='my-7 row'>
                        <div className='mb-6 col-sm-12'>
                            <CustomDropdown
                                label={'Billing Frequency'}
                                name={'frequencyTypeId'}
                                placeholder='Select Frequency'
                                value={billingDetailsData?.frequencyTypeId}
                                onChange={updateBillingDetailsData}
                                options={billingFrequencyDropdown}
                                errorMessage={salesErrorData?.frequencyTypeId}
                                required
                                disabled={location?.state?.tid}
                            />
                        </div>

                        <div className='col-sm-12'>
                            <CustomInput
                                placeholder={'Enter Value'}
                                label={'Minimum Monthly Fee'}
                                prefixText='$'
                                name='minimumMonthlyFee'
                                onChange={updateBillingDetailsData}
                                value={billingDetailsData?.minimumMonthlyFee}
                                errorMessage={salesErrorData?.minimumMonthlyFee}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='text-xl-bold text-sqGray900 mb-7'>Base Fee</div>
                    <div className='d-flex gap-5 align-items-center flex-wrap mb-6'>
                        <CustomRadioButton1
                            isChecked={pidOrWorkerView === 'per_user_per_month'}
                            handleChange={() => setPidOrWorkerView('per_user_per_month')}
                            // disabled={isDisable}
                            // size={isSmall ? RADIO_SIZE.sm : RADIO_SIZE.md}
                            label='Per user per month'
                        />
                        <CustomRadioButton1
                            label='Per PID and M2'
                            handleChange={() => setPidOrWorkerView('per_pid_and_m2')}
                            isChecked={pidOrWorkerView === 'per_pid_and_m2'}
                            disabled={selectedButton == 'Pest'}
                            // size={isSmall ? RADIO_SIZE.sm : RADIO_SIZE.md}
                            // disabled={skeletonLoading || apiLoader}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-6'>
                        {/* <div className='mb-5' style={{flexGrow: 1}}> */}
                        <CustomInput
                            label={'Fee for Processing PID'}
                            placeholder={'Enter Value'}
                            name='baseFreeForProcessingPID'
                            prefixText='$'
                            onChange={updateBillingDetailsData}
                            value={billingDetailsData?.baseFreeForProcessingPID}
                            required
                            errorMessage={salesErrorData?.baseFreeForProcessingPID}
                        />
                        {/* </div> */}
                    </div>
                    {pidOrWorkerView === 'per_pid_and_m2' ? (
                        <div className='col-sm-6'>
                            <CustomInput
                                placeholder={'Enter Value'}
                                label={'Fee per PID and M2'}
                                name='basefreePerPIDandM2'
                                prefixText='$'
                                onChange={updateBillingDetailsData}
                                value={billingDetailsData?.basefreePerPIDandM2}
                                required
                                errorMessage={salesErrorData?.basefreePerPIDandM2}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {/* <div className='d-flex align-item-center pt-5'>
                    <CustomToggleBtn
                        checked={switchIsChecked}
                        onChange={onChangeSwitch}
                        // id={'My_client_Table_Toggle'}
                    />
                    <div className='ps-3'>
                        <CommonLabel label='SequiPay' />
                    </div>
                </div> */}
                <Show>
                    <Show.When isVisible={workTypeContractors}>
                        <div className='text-xl-bold text-sqGray900 mb-7 mt-6'>
                            Worker Type Billing - 1099
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                {/* <div className='mb-5' style={{flexGrow: 1}}> */}
                                <CustomInput
                                    label={'Fee per check for 1099 Workers'}
                                    placeholder={'Enter Value'}
                                    name='workTypeFreeForProcessingPID'
                                    prefixText='$'
                                    onChange={updateBillingDetailsData}
                                    value={billingDetailsData?.workTypeFreeForProcessingPID}
                                    required
                                    errorMessage={salesErrorData?.workTypeFreeForProcessingPID}
                                />
                                {/* </div> */}
                            </div>
                            <div className='col-sm-6'>
                                {/* <div className='text-sqGray900 mt-8'>
                                    Fee for each One Time Payment processed
                                </div> */}

                                {/* <div className='mb-5' style={{flexGrow: 1}}> */}
                                <CustomInput
                                    placeholder={'Enter Value'}
                                    label={'Fee for each One Time Payment processed via Sequifi'}
                                    name='workTypeViaSequif'
                                    prefixText='$'
                                    onChange={updateBillingDetailsData}
                                    value={billingDetailsData?.workTypeViaSequif}
                                    required
                                    errorMessage={salesErrorData?.workTypeViaSequif}
                                />
                                {/* </div> */}
                            </div>
                        </div>
                    </Show.When>
                </Show>

                <Show>
                    <Show.When isVisible={workTypeFirst}>
                        <div className='text-xl-bold text-sqGray900 mb-7 mt-6'>
                            Worker Type Billing - W2
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <CustomInput
                                    label={'Fee for employee per month'}
                                    placeholder={'Enter Value'}
                                    name='workTypeFreeForEmployeePerMonth'
                                    prefixText='$'
                                    onChange={updateBillingDetailsData}
                                    value={billingDetailsData?.workTypeFreeForEmployeePerMonth}
                                    required
                                    errorMessage={salesErrorData?.workTypeFreeForEmployeePerMonth}
                                />
                            </div>
                            <div className='col-sm-6'>
                                <CustomInput
                                    placeholder={'Enter Value'}
                                    label={'Over Time rate per hour'}
                                    name='workTypeOverTimeRatePerHour'
                                    prefixText='$'
                                    onChange={updateBillingDetailsData}
                                    value={billingDetailsData?.workTypeOverTimeRatePerHour}
                                    required
                                    errorMessage={salesErrorData?.workTypeOverTimeRatePerHour}
                                />
                            </div>
                        </div>
                    </Show.When>
                </Show>
                <div className='mt-17 mx-2'>
                    <CustomButton2
                        buttonLabel='Save & Continue'
                        buttonSize={BUTTON_SIZE.lg}
                        buttonType={BUTTON_TYPE.primary}
                        onClick={saveAndContinue}
                        // width={'225px'}
                    />
                </div>
            </div>
        </div>
    )
}

export default BillingDetails
