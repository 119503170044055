import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {
    TABLE_BORDER,
    formattedNumberFields,
    formattedPhoneNumber,
} from '../../../../../helpers/CommonHelpers'
import {BILLING_PRODUCTS, getValidDate} from '../../../../../constants/constants'
import {fontsFamily} from '../../../../../assets/fonts/fonts'
import Show from '../../../../../customComponents/show/Show'
import useSequifiConfig from '../../../../../sequiConfig/useSequifiConfig'

const ViewBillingInvoice = ({data}) => {
    const {basedOnCompanyType} = useSequifiConfig()
    return (
        <div style={{padding: '10px'}}>
            <meta charSet='UTF-8' />
            <div className='page' style={{fontFamily: fontsFamily.manrope}}>
                <div style={{width: '100%', marginBottom: '30px'}}>
                    <div className='' style={{position: 'relative', marginBottom: 40}}>
                        <div
                            style={{
                                backgroundColor: '#6078ec',
                                width: '100%',
                                height: '94px',
                                borderRadius: '15px',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <span
                                style={{
                                    position: 'absolute',
                                    backgroundColor: '#8598fd',
                                    bottom: '30px',
                                    right: '52px',
                                    width: '200px',
                                    height: '200px',
                                    borderRadius: '50%',
                                }}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    backgroundColor: '#8598fd',
                                    top: '30px',
                                    left: '10px',
                                    width: '200px',
                                    height: '200px',
                                    borderRadius: '50%',
                                }}
                            />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                width: '112px',
                                height: '112px',
                                top: '-5px',
                                left: '20px',
                                borderRadius: '25px',
                                border: '1px solid #ededed',
                                borderTop: 'none',
                                backgroundColor: '#FFFFFF',
                            }}
                        >
                            <KTSVG
                                path='/media/icons/duotune/art/SequifiSLogo.svg'
                                className='cursor-pointer'
                                svgClassName='w-auto h-100px'
                            />
                        </div>

                        <div
                            className=''
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '32px',
                                width: '50%',
                                textAlign: 'right',
                            }}
                        >
                            <h3
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#fff',
                                    fontSize: '22px',
                                    paddingBottom: '0px',
                                    lineHeight: '30px',
                                    marginBottom: '14px',
                                }}
                            >
                                TAX INVOICE
                            </h3>
                            <span
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#000000',
                                    fontSize: '14px',
                                    // lineHeight: '30px',
                                    fontWeight: 600,
                                    backgroundColor: '#fff',
                                    padding: '8px 15px',
                                    borderRadius: '25px',
                                }}
                            >
                                {data?.invoice_calculation?.invoice_no ?? '-'}
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            // marginTop: '40px',
                            gap: '10px',
                        }}
                    >
                        <div style={{width: '33.33%'}}>
                            <h3
                                className=' '
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#19213d',
                                    fontSize: '12px',
                                    lineHeight: '30px',
                                    marginBottom: '0px',
                                    fontWeight: 700,
                                }}
                            >
                                Bill From: {data?.bill_from?.company_name ?? '-'}
                            </h3>
                            <div
                                className=' '
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#616161',
                                    fontSize: '8px',
                                    lineHeight: '12px',
                                    fontWeight: 500,
                                    margin: '0px',
                                }}
                            >
                                <div className='mb-2'> {data?.bill_from?.address ?? '-'}</div>
                                <br /> {data?.bill_from?.phone_number ?? '-'}
                            </div>
                            <div
                                className='mb-2'
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#616161',
                                    fontSize: '8px',
                                    lineHeight: '12px',
                                    fontWeight: 500,
                                    margin: '0px',
                                }}
                            >
                                {data?.bill_from?.email ?? '-'}
                            </div>
                            <div
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#212121',
                                    fontSize: '8px',
                                    lineHeight: '12px',
                                    fontWeight: 700,
                                    margin: '0px',
                                }}
                            >
                                <strong>TAX ID: {data?.bill_from?.tax_id ?? '-'}</strong>
                            </div>
                        </div>
                        <div
                            style={{
                                width: '33.33%',
                                borderLeft: '1px solid #ebeff6',
                                paddingLeft: '25px',
                            }}
                        >
                            <h3
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#19213d',
                                    fontSize: '12px',
                                    lineHeight: '30px',
                                    marginBottom: '0px',
                                    fontWeight: 600,
                                }}
                            >
                                Bill To: {data?.bill_to?.business_name ?? '-'}
                            </h3>
                            <div
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#616161',
                                    fontSize: '8px',
                                    lineHeight: '12px',
                                    fontWeight: 500,
                                    margin: '0px',
                                }}
                            >
                                <span className='mb-2'>
                                    {data?.bill_to?.business_address},{' '}
                                    {data?.bill_to?.business_city}, {data?.bill_to?.business_state},{' '}
                                    {data?.bill_to?.country}, {data?.bill_to?.business_zip}
                                </span>
                                <br /> {formattedPhoneNumber(data?.bill_to?.business_phone)}
                            </div>
                            <div
                                className='mb-2'
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#616161',
                                    fontSize: '8px',
                                    lineHeight: '12px',
                                    fontWeight: 500,
                                    margin: '0px',
                                }}
                            >
                                {data?.bill_to?.company_email ?? '-'}
                            </div>
                            <div
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#212121',
                                    fontSize: '8px',
                                    lineHeight: '12px',
                                    fontWeight: 700,
                                    margin: '0px',
                                }}
                            >
                                <strong>TAX ID: {data?.bill_to?.business_ein ?? '-'}</strong>
                            </div>
                        </div>
                        <div
                            style={{
                                width: '33.33%',
                                borderLeft: '1px solid #ebeff6',
                                paddingLeft: '25px',
                            }}
                        >
                            <h3
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#212121',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    marginBottom: '0px',
                                    fontWeight: 600,
                                }}
                            >
                                Invoice:
                            </h3>
                            <div
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#616161',
                                    fontSize: '8px',
                                    lineHeight: '12px',
                                    fontWeight: 500,
                                    margin: '0px',
                                }}
                            >
                                Invoice date:{' '}
                                <strong style={{color: '#212121'}}>
                                    {getValidDate(
                                        data?.invoice_calculation?.billing_date,
                                        'MMM DD, YYYY'
                                    )}
                                </strong>
                            </div>
                            <div
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#616161',
                                    fontSize: '8px',
                                    lineHeight: '12px',
                                    fontWeight: 500,
                                    margin: '0px',
                                }}
                            >
                                Due date:{' '}
                                <strong style={{color: '#212121'}}>
                                    {getValidDate(
                                        data?.invoice_calculation?.due_date,
                                        'MMM DD, YYYY'
                                    )}
                                </strong>
                            </div>
                            <div
                                style={{
                                    boxSizing: 'border-box',
                                    color: '#616161',
                                    fontSize: '8px',
                                    lineHeight: '12px',
                                    fontWeight: 500,
                                    margin: '0px',
                                }}
                            >
                                Invoice no.:
                                <strong style={{color: '#212121'}}>
                                    {data?.invoice_calculation?.invoice_no ?? '-'}
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '30px'}} />
                    <div
                        className='table-responsive shadow-sm bg-cmwhite border border-1 border-cmGrey300'
                        style={{
                            borderRadius: 10,
                            verticalAlign: 'top',
                            tableLayout: 'fixed',
                            // width: `100%`,
                            padding: '10px',
                        }}
                    >
                        <table className='table w-100'>
                            <thead className={'bg-cmwhite'}>
                                <tr
                                    className='bg-cmwhite text-cmGrey900  border border-dark border-bottom-1 border-top-0 border-left-0 border-right-0 border-cmGrey300'
                                    style={{
                                        fontSize: '7px',
                                        fontFamily: fontsFamily.manrope,
                                    }}
                                >
                                    <th className='w-auto'>
                                        <span
                                            style={{
                                                backgroundColor: '#eff1fd',
                                                padding: '5px 8px',
                                                borderRadius: '25px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Product
                                        </span>
                                    </th>
                                    <th className='w-auto'>
                                        <span
                                            style={{
                                                backgroundColor: '#eff1fd',
                                                padding: '5px 8px',
                                                borderRadius: '25px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Price for
                                        </span>
                                    </th>
                                    <th className='w-auto'>
                                        <span
                                            style={{
                                                backgroundColor: '#eff1fd',
                                                padding: '5px 8px',
                                                borderRadius: '25px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Listed Price
                                        </span>
                                    </th>
                                    <th className='w-auto'>
                                        <span
                                            style={{
                                                backgroundColor: '#eff1fd',
                                                padding: '5px 8px',
                                                borderRadius: '25px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Discounted Price
                                        </span>
                                    </th>
                                    <th className='w-auto'>
                                        <span
                                            style={{
                                                backgroundColor: '#eff1fd',
                                                padding: '5px 8px',
                                                borderRadius: '25px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Amount exe. tax
                                        </span>
                                    </th>
                                    <th className='w-auto'>
                                        <span
                                            style={{
                                                backgroundColor: '#eff1fd',
                                                padding: '5px 8px',
                                                borderRadius: '25px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Discount
                                        </span>
                                    </th>
                                    <th className='w-auto'>
                                        <span
                                            style={{
                                                backgroundColor: '#eff1fd',
                                                padding: '5px 8px',
                                                borderRadius: '25px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Taxable Amount
                                        </span>
                                    </th>
                                    <th className='w-auto'>
                                        <span
                                            style={{
                                                backgroundColor: '#eff1fd',
                                                padding: '5px 8px',
                                                borderRadius: '25px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Sales Tax({' '}
                                            {formattedNumberFields(
                                                data?.invoice_calculation?.sales_tax_per,
                                                '%'
                                            )}
                                            )
                                        </span>
                                    </th>
                                    <th className='w-auto'>
                                        <span
                                            style={{
                                                backgroundColor: '#eff1fd',
                                                padding: '5px 8px',
                                                borderRadius: '25px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Total Amount
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className={''}>
                                {data?.invoice_data?.length > 0 ? (
                                    <>
                                        {data?.invoice_data?.map((item, i) => (
                                            <React.Fragment key={`invoice_${i}`}>
                                                <Show>
                                                    <Show.When
                                                        key={`invoice_when_${i}`}
                                                        isVisible={
                                                            item?.plan_id ==
                                                            BILLING_PRODUCTS.sequifi_payroll
                                                        }
                                                    >
                                                        <tr
                                                            key={`invoice_${i}`}
                                                            className='text-cmGrey800  border border-dark border-bottom-1 border-top-0 border-left-0 border-right-0 border-cmGrey300'
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Sequifi Payroll
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>Unique PIDs:</div>
                                                                <div>M2 Completed:</div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>
                                                                    $ {item?.unique_pid_rack_price}{' '}
                                                                    /{' '}
                                                                    {
                                                                        basedOnCompanyType?.secondaryUnitType
                                                                    }
                                                                </div>
                                                                <div>
                                                                    $ {item?.m2_rack_price} /{' '}
                                                                    {
                                                                        basedOnCompanyType?.secondaryUnitType
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>
                                                                    ${' '}
                                                                    {
                                                                        item?.unique_pid_discount_price
                                                                    }{' '}
                                                                    /{' '}
                                                                    {
                                                                        basedOnCompanyType?.secondaryUnitType
                                                                    }
                                                                </div>
                                                                <div>
                                                                    $ {item?.m2_discount_price} /{' '}
                                                                    {
                                                                        basedOnCompanyType?.secondaryUnitType
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.used_credit
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    verticalAlign: 'middle',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.taxable_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                    verticalAlign: 'middle',
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.sales_tax_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '11px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                    verticalAlign: 'middle',
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.grand_total
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </Show.When>
                                                    <Show.When
                                                        isVisible={
                                                            item?.plan_id ==
                                                            BILLING_PRODUCTS.s_clearance
                                                        }
                                                    >
                                                        <tr
                                                            key={`invoice_sclearcnce_${i}`}
                                                            className='text-cmGrey800   border border-dark border-bottom-1 border-top-0 border-left-0 border-right-0 border-cmGrey300'
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                S Clearance
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>Background Report:</div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>
                                                                    {formattedNumberFields(
                                                                        item?.unique_pid_rack_price
                                                                    )}{' '}
                                                                    / Report
                                                                </div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>
                                                                    {formattedNumberFields(
                                                                        item?.unique_pid_discount_price
                                                                    )}{' '}
                                                                    / Report
                                                                </div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.used_credit
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.taxable_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.sales_tax_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '11px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.grand_total
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </Show.When>
                                                    <Show.When
                                                        isVisible={
                                                            item?.plan_id ==
                                                            BILLING_PRODUCTS.sequi_pay
                                                        }
                                                    >
                                                        <tr
                                                            key={`invoice_pay_${i}`}
                                                            className='text-cmGrey800  '
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Sequipay
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>Payroll checks:</div>
                                                                <div>One time payment:</div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>
                                                                    $ {item?.unique_pid_rack_price}{' '}
                                                                    / check
                                                                </div>
                                                                <div>
                                                                    $ {item?.m2_rack_price} / check
                                                                </div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>
                                                                    $
                                                                    {
                                                                        item?.unique_pid_discount_price
                                                                    }{' '}
                                                                    /{' '}
                                                                    {
                                                                        basedOnCompanyType?.secondaryUnitType
                                                                    }
                                                                </div>
                                                                <div>
                                                                    $ {item?.m2_discount_price} /{' '}
                                                                    {
                                                                        basedOnCompanyType?.secondaryUnitType
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                    verticalAlign: 'middle',
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.used_credit
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.taxable_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    verticalAlign: 'middle',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.sales_tax_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '11px',
                                                                    verticalAlign: 'middle',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.grand_total
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </Show.When>
                                                    <Show.When
                                                        isVisible={
                                                            item?.plan_id ==
                                                            BILLING_PRODUCTS.user_wise_billing
                                                        }
                                                    >
                                                        <tr
                                                            key={`invoice_billing_${i}`}
                                                            className='text-cmGrey800   border border-dark border-bottom-1 border-top-0 border-left-0 border-right-0 border-cmGrey300'
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Sequifi Payroll
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>Active Users:</div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>
                                                                    {formattedNumberFields(
                                                                        item?.unique_pid_rack_price
                                                                    )}{' '}
                                                                    / User
                                                                </div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>
                                                                    {formattedNumberFields(
                                                                        item?.unique_pid_discount_price
                                                                    )}{' '}
                                                                    / User
                                                                </div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.used_credit
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.taxable_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.sales_tax_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '11px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.grand_total
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </Show.When>
                                                    <Show.When
                                                        isVisible={
                                                            item?.plan_id ==
                                                            BILLING_PRODUCTS.sequi_ai
                                                        }
                                                    >
                                                        <tr
                                                            key={`invoice_AI_${i}`}
                                                            className='text-cmGrey800  '
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                SequiAI
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>1st Million Credits:</div>
                                                                <div>Additional Credits:</div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>
                                                                    ${' '}
                                                                    {
                                                                        item?.extra_info
                                                                            ?.first_credits
                                                                    }
                                                                </div>
                                                                <div>
                                                                    $
                                                                    {
                                                                        item?.extra_info
                                                                            ?.additioonal_credits
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <div>
                                                                    ${' '}
                                                                    {
                                                                        item?.extra_info
                                                                            ?.first_credit_discount
                                                                    }
                                                                </div>
                                                                <div>
                                                                    $
                                                                    {
                                                                        item?.extra_info
                                                                            ?.additioonal_credit_discount
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                    verticalAlign: 'middle',
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.used_credit
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.taxable_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    verticalAlign: 'middle',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.sales_tax_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '11px',
                                                                    verticalAlign: 'middle',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.grand_total
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </Show.When>
                                                    <Show.When
                                                        isVisible={
                                                            item?.plan_id ==
                                                            BILLING_PRODUCTS.sales_crm
                                                        }
                                                    >
                                                        <tr
                                                            key={item?.id}
                                                            className='text-cmGrey800  '
                                                            style={{
                                                                fontSize: '14px',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                SequiCRM
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                New Jobs:
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.unique_pid_rack_price
                                                                )}{' '}
                                                                / Job
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '8px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.unique_pid_rack_price
                                                                )}{' '}
                                                                / Job
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                    verticalAlign: 'middle',
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.used_credit
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    lineHeight: '14px',
                                                                    verticalAlign: 'middle',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.taxable_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey800 w-auto'
                                                                style={{
                                                                    fontSize: '9px',
                                                                    verticalAlign: 'middle',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.sales_tax_amount
                                                                )}
                                                            </td>
                                                            <td
                                                                className='text-cmGrey900 w-auto'
                                                                style={{
                                                                    fontSize: '11px',
                                                                    verticalAlign: 'middle',
                                                                    lineHeight: '14px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {formattedNumberFields(
                                                                    item?.grand_total
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </Show.When>
                                                </Show>
                                            </React.Fragment>
                                        ))}
                                    </>
                                ) : (
                                    <tr key='no-data'>
                                        <td
                                            colSpan={12}
                                            style={{
                                                textAlign: 'center',
                                                fontFamily: 'Manrope',
                                                fontWeight: '500',
                                                fontSize: 14,
                                                paddingTop: 20,
                                                paddingBottom: 20,
                                            }}
                                        >
                                            No data found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Bottom Amount Totals */}
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                        <div
                            style={{
                                border: '1px solid #a5a5a5',
                                borderRadius: '15px',
                                padding: '15px',
                                marginTop: '50px',
                                backgroundColor: '#6078ec',
                                width: '50%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    width: '100%',
                                    marginBottom: '10px',
                                    paddingBottom: '10px',
                                    borderBottom: '1px solid #e3e3e3',
                                }}
                            >
                                <div
                                    style={{
                                        boxSizing: 'border-box',
                                        color: '#fff',
                                        fontSize: '10px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        margin: '0px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Amount exc. Tax
                                </div>
                                <div
                                    style={{
                                        boxSizing: 'border-box',
                                        color: '#fff',
                                        fontSize: '10px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        margin: '0px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {formattedNumberFields(
                                        data?.invoice_calculation?.exe_tax_amount
                                    )}
                                </div>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    width: '100%',
                                    marginBottom: '10px',
                                    paddingBottom: '10px',
                                    borderBottom: '1px solid #e3e3e3',
                                }}
                            >
                                <div
                                    style={{
                                        boxSizing: 'border-box',
                                        color: '#fff',
                                        fontSize: '10px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        margin: '0px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Discount (Credit:{' '}
                                    {formattedNumberFields(data?.invoice_calculation?.total_credit)}
                                    )
                                </div>
                                <div
                                    style={{
                                        boxSizing: 'border-box',
                                        color: '#fff',
                                        fontSize: '10px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        margin: '0px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {/* {Number(data?.invoice_data?.credit_amount) -
                                        Number(data?.invoice_data?.amount) >=
                                    0
                                        ? formattedNumberFields(data?.invoice_data?.amount)
                                        : formattedNumberFields(data?.invoice_data?.credit_amount)} */}
                                    {formattedNumberFields(
                                        data?.invoice_calculation?.discount_credit
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    width: '100%',
                                    marginBottom: '10px',
                                    paddingBottom: '10px',
                                    borderBottom: '1px solid #e3e3e3',
                                }}
                            >
                                <div
                                    style={{
                                        boxSizing: 'border-box',
                                        color: '#fff',
                                        fontSize: '10px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        margin: '0px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Taxable Amount:
                                </div>
                                <div
                                    style={{
                                        boxSizing: 'border-box',
                                        color: '#fff',
                                        fontSize: '10px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        margin: '0px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {formattedNumberFields(
                                        data?.invoice_calculation?.texable_amounnt
                                    )}
                                </div>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    width: '100%',
                                    marginBottom: '10px',
                                    paddingBottom: '10px',
                                    borderBottom: '1px solid #e3e3e3',
                                }}
                            >
                                <div
                                    style={{
                                        boxSizing: 'border-box',
                                        color: '#fff',
                                        fontSize: '10px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        margin: '0px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Sales TAX (
                                    {formattedNumberFields(
                                        data?.invoice_calculation?.sales_tax_per,
                                        '%'
                                    )}
                                    )
                                </div>
                                <div
                                    style={{
                                        boxSizing: 'border-box',
                                        color: '#fff',
                                        fontSize: '10px',
                                        lineHeight: '14px',
                                        fontWeight: 500,
                                        margin: '0px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {formattedNumberFields(
                                        data?.invoice_calculation?.sales_tax_amount
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                            >
                                <div
                                    style={{
                                        boxSizing: 'border-box',
                                        color: '#fff',
                                        fontSize: '10px',
                                        lineHeight: '14px',
                                        fontWeight: 700,
                                        margin: '0px',
                                        textAlign: 'left',
                                    }}
                                >
                                    AMOUNT DUE
                                </div>
                                <div
                                    style={{
                                        boxSizing: 'border-box',
                                        color: '#fff',
                                        fontSize: '18px',
                                        lineHeight: '22px',
                                        fontWeight: 800,
                                        margin: '0px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {formattedNumberFields(data?.invoice_calculation?.grand_total)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'end',
                    }}
                >
                    <div
                        style={{
                            boxSizing: 'border-box',
                            color: '#616161',
                            fontSize: '12px',
                            lineHeight: '12px',
                            fontWeight: 600,
                            margin: '0px',
                            textAlign: 'center',
                        }}
                    >
                        Thank you for your Business!
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewBillingInvoice
