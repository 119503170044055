import {useLocation, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {
    formattedNumberFields,
    sendDataToReducer,
    TABLE_BORDER,
} from '../../../../helpers/CommonHelpers'
import {CustomSortSvg} from '../../../../_metronic/helpers/components/CustomSortSVG'
import {MULTITENANT_CLIENT_DIRECTORY_DROPDOWN_LIST} from '../../../../constants/constants'
import Pagination from '../../admin/sequidocs/component/Pagination'
import CustomTableSkeleton from '../../../../customComponents/customSkeleton/CustomTableSkeleton'
import {useCallback, useEffect, useState} from 'react'
import CustomDropdown from '../../../../customComponents/customInputs/customDropdown/CustomDropdown'
import {getPayrollChecksService} from '../../../../services/Services'
import useAPI from '../../../../hooks/useAPI'
import {useSelector} from 'react-redux'
import {getMultitenantFilterDataSelector} from '../../../../redux/selectors/FilterSelectors'
import {useDispatch} from 'react-redux'
import {SET_MULTITENANT_FILTER_DATA} from '../../../../redux/reducers/FilterReducer'
import Show from '../../../../customComponents/show/Show'
import CustomCardSkeleton from '../../../../customComponents/customSkeleton/CustomCardSkeleton'

const PaycheckPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const filterData = useSelector(getMultitenantFilterDataSelector)
    const {callAPI, initAPI, loading: corporateLoading} = useAPI()

    const [paycheckTableData, setpaycheckTableData] = useState()
    const [filter, setFilter] = useState(filterData?.filter)
    const [page, setPage] = useState(filterData?.page)
    const [limit, setLimit] = useState(filterData?.limit)
    const [sortingOrder, setSortingOrder] = useState(null)
    const [sortValue, setSortValue] = useState(null)

    // const getPayrollCheckData = () => {
    //     const body = {
    //         tenant_id: location?.state?.tid,
    //         filter: 'this_year',
    //     }
    //     getPayrollChecksService(body)
    //         .then((res) => {
    //             setpaycheckTableData(res)
    //         })
    //         .catch((e) => {
    //             CustomToast.error(getErrorMessageFromResponse(e))
    //         })
    //         .finally(() => setLoading(false))
    // }

    const onChangeFilter = (data) => {
        setFilter(data)
    }

    useEffect(() => {
        getPayrollCheckData()
        sendDataToReducer(dispatch, SET_MULTITENANT_FILTER_DATA, {
            ...filterData,
            filter,
            page: page,
            limit: limit,
        })
    }, [page, limit, filter, sortValue, sortingOrder])

    const onPress = (item) => {
        setSortValue(item)
        setSortingOrder(sortValue !== item ? 'asc' : sortingOrder === 'asc' ? 'desc' : 'asc')
        setPage(1)
    }
    const getPayrollCheckData = useCallback(() => {
        const body = {
            tenant_id: location?.state?.tid,
            filter: filter,
            page: page,
            perpage: Number(limit),
        }
        initAPI().then((signal) => {
            callAPI(getPayrollChecksService(body, signal))
                .then((res) => {
                    setpaycheckTableData(res)
                })
                .catch(() => {})
        })
    }, [callAPI, initAPI, filter, page, limit, sortValue, sortingOrder])
    return (
        <div className='ow container gap-5 mx-auto'>
            {/* <CustomLoader full visible={loading} /> */}
            <div className='d-flex justify-content-between mb-2'>
                <div className='d-flex align-items-center mb-5 gap-3'>
                    <div onClick={() => navigate(-1)}>
                        <KTSVG
                            path='/media/icons/duotune/art/back-square.svg'
                            className='cursor-pointer'
                            svgClassName='w-25px h-25px'
                        />
                    </div>
                    <div className='text-sqGray900 text-xl-bold flex-grow-1'>
                        Flex Power | Payroll Checks
                    </div>
                </div>
                <div className='col-sm-3 col-lg-2 col-xl-2'>
                    <CustomDropdown
                        id={'One_time_Payment_Time_Frame_Dropdown'}
                        value={filter}
                        showClear={false}
                        searching={false}
                        options={MULTITENANT_CLIENT_DIRECTORY_DROPDOWN_LIST}
                        valueKey='value'
                        displayKey='name'
                        onChange={(e) => onChangeFilter(e.target.value)}
                    />
                </div>
            </div>
            <Show>
                <Show.When isVisible={corporateLoading}>
                    <CustomCardSkeleton col='col-sm-2' />
                </Show.When>
                <Show.When isVisible={!corporateLoading}>
                    <div className='container mb-6'>
                        <div className='row gap-6'>
                            <>
                                {/* Card 1 */}
                                <div
                                    className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200'
                                    style={{borderRadius: 10}}
                                >
                                    <div className='card-body'>
                                        <div className='card-title text-sqGray900 text-xl-bold'>
                                            {0}
                                        </div>
                                        <div className='card-text text-sqGray800 text-sm-semibold'>
                                            No. of Checks
                                        </div>
                                    </div>
                                </div>
                                {/* Card 2 */}
                                <div
                                    className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200'
                                    style={{borderRadius: 10}}
                                >
                                    <div className='card-body'>
                                        <div className='card-title text-sqGray900  text-xl-bold'>
                                            {paycheckTableData?.payment_total}
                                        </div>
                                        <div className='card-text text-sqGray800  text-sm-semibold'>
                                            Payment Total
                                        </div>
                                    </div>
                                </div>
                                {/* Card 3 */}
                                <div
                                    className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200'
                                    style={{borderRadius: 10}}
                                >
                                    <div className='card-body'>
                                        <div className='card-title text-sqGray900  text-xl-bold'>
                                            {formattedNumberFields(
                                                paycheckTableData?.total_price,
                                                '$'
                                            )}
                                        </div>
                                        <div className='card-text text-sqGray800 text-sm-semibold'>
                                            Total Cost
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </Show.When>
            </Show>

            <div className='bg-cmwhite mt-6' style={{borderRadius: 10, verticalAlign: 'top'}}>
                <div className='table-responsive' style={{borderRadius: 10}}>
                    <table className='table'>
                        <thead className={TABLE_BORDER}>
                            <tr className='text-sqGray800 bg-sqGray200 text-sm-semibold text-nowrap'>
                                <th className='p-5 text-nowrap'>Check ID</th>
                                <th className='p-5 text-nowrap'>Employee Name</th>
                                <th className='p-5 text-nowrap'>Check Date</th>

                                <th className='p-5 text-nowrap'>Payment Amount</th>
                                <th className='p-5 text-nowrap'>
                                    <div className='d-flex align-items-center'>
                                        Price Billed
                                        <CustomSortSvg
                                            id={'comapny_billing_Sort_Icon'}
                                            sortingOrder={
                                                sortValue === 'billed_price' ? sortingOrder : null
                                            }
                                            onClick={() => onPress('billed_price')}
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className={TABLE_BORDER}>
                            {corporateLoading ? (
                                <CustomTableSkeleton coloums={5} />
                            ) : paycheckTableData && paycheckTableData?.data?.data?.length > 0 ? (
                                paycheckTableData?.data?.data?.map((item, index) => (
                                    <tr className='text-sqGray800 stripRow '>
                                        <td className='p-5 text-nowrap text-xs-medium'>
                                            <div className='d-flex gap-4'>
                                                {item?.checkid ?? '-'}
                                            </div>
                                        </td>
                                        <td className='p-5 text-nowrap text-xs-medium'>
                                            {item?.name ?? '-'}
                                        </td>
                                        <td className='p-5 text-nowrap text-xs-medium'>
                                            {item?.checkType ?? '-'}
                                        </td>
                                        <td className='p-5 text-nowrap text-xs-medium'>
                                            {item?.price ?? '-'}
                                        </td>

                                        <td className='p-5 text-nowrap text-xs-bold'>
                                            {formattedNumberFields(item?.billed_price, '$')}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={4}
                                        className='text-center py-3'
                                        style={{
                                            fontFamily: 'Manrope',
                                            fontWeight: '500',
                                            fontSize: 14,
                                        }}
                                    >
                                        No data found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='pb-5 pe-5'>
                    {paycheckTableData?.data?.data?.length > 0 && (
                        <Pagination
                            tableName={'Pay_Roll_Check'}
                            page={page}
                            totalPages={paycheckTableData?.data?.last_page}
                            limit={limit}
                            setLimit={setLimit}
                            setPage={(changedPage) => setPage(changedPage)}
                        />
                    )}
                </div>
            </div>
            {/* <div className='d-flex justify-content-center align-items-center mt-6'>
                <CustomButton2
                    buttonLabel='Load More'
                    buttonSize={BUTTON_SIZE_STYLE.lg}
                    buttonType={BUTTON_TYPE.primary}
                />
            </div> */}
        </div>
    )
}

export default PaycheckPage
