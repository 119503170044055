import {GOOGLE_MAPS_KEY} from '../constants/constants'

const EndPoints = {
    //Admin Portal(multitenancy)
    postTenantBasicInformation: '/tenant_basic_information',
    postBillingFrequencyDropdownList: '/billing_frequency_dropdown_list',
    postTenantBillingDetails: 'tenant_billing_details',
    setupTenantAdmin: 'tenant_admin_setup',
    setupTenantPayroll: 'tenant_payroll',

    getTenantDetailsClientDirectory: 'get_tenant_details',
    getTenantBillingGraphData: 'get_billing_graph_data',
    postValidateTenantOnEvery: 'validate_tenant_on_every',
    getTotalEarning: 'get_total_earning',
    getTenantModulesList: 'get_tenant_modules_list',
    getPayrollCheckOneTimePayments: 'get_payroll_checks_one_time_payments',
    getTenantDetailsByTenantId: 'get_tenant_details_by_tenant_id',
    getActiveUserTotalSales: 'get_active_user_total_sales',
    getPaymentRecords: 'get_payment_details_by_tenant_id',
    getAdministrativeSetUp: 'get_admin_user_list',
    UpdateTenantBasicInfomation: 'update_tenant_basic_information',
    getDocumentsClientDirectoryList: 'get_tenant_documents_list',
    dashboardGraph: 'get_dashboard_earning_graph_data',
    getTenantUniquePids: 'tenant_unique_pids',
    getTenantm2Completed: 'tenant_m2_completed',
    getTenantDetailsDashboardList: 'get_tenant_details_list',
    getPayrollChecks: 'get_paychecks_by_tenant_id',
    getBackgroundChecks: 'get_background_by_tenant_id',
    putAdministrativeSetupSuspendUser: 'tenant_suspend_user_access',
    updateTenantAdminSetUp: 'update_tenant_admin_setup',
    putAdministrativeSetupMakeUserGroup: 'tenant_make_user_group_admin',
    getPaymentInvoice: 'tenant_get_invoice_data',
    // multitenancy (Dashboard)
    getClientsAndActiveUsersDashboard: 'get_clients_and_active_users',
    //Client Portal
    getBitbucketBranches: '/branches',
    callThirdPartyApi: '/third-party-api',
    subDomainAvailiblityCheck: '/master/subdomain-availability-check',
    uploadImageToS3: '/setting/image_drag_and_drop_setting',

    // Google Maps
    getCurrentTimeFromTimeZone: 'https://worldtimeapi.org/api/timezone',
    getLatLongFromAddress: `https://maps.googleapis.com/maps/api/geocode/json`,
    getPlaceAddress: `https://maps.googleapis.com/maps/api/place/autocomplete/json`,
    getLocationFromAddress: `https://maps.googleapis.com/maps/api/geocode/json`,
    getAddressFromReverseGeocoding: `https://maps.googleapis.com/maps/api/geocode/json`,
    getTimeZoneFromLatLong: `https://maps.googleapis.com/maps/api/timezone/json`,

    // Auth
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    getCompanyProfile: '/setting/get-company-profile',
    getCompanyProfileWithoutAuth: '/get-company-profile-without-auth',
    updateCompanyProfile: '/setting/update-company-profile',
    logout: '/logout',
    getUserProfile: '/get_userdata',
    changePassword: '/change_password',
    updateDeviceToken: '/update_device_token',
    resetApp: '/setting/app/reset',
    loginAsUser: '/login-user',
    switchToAdmin: 'switch-to-admin',

    //Global
    getAllLocationList: '/setting/AllGeneralCodeList',
    getAllStatesAndCities: '/city_by_state',
    getUsedStates: '/setting/get-all-user-state',
    getcityList: '/cities_by_state_id',
    getRedlineFromLocation: '/setting/get_data_from_location',

    //Admin > Setup > Company
    getCompanySetting: '/setting/get-company-setting',
    updateCompanySetting: '/setting/setup-active-inactive',

    // Admin > Setup > Company Margin
    getCompanyMargin: '/setting/get_company_margin',
    updateCompanyMargin: '/setting/update_company_margin',

    // Admin > Setting
    getOverridesOnTwoAccountStatus: '/management/get_override_system_status?id=1',
    updateOverridesOnTwoAccountStatus: '/management/override_system_status',
    getCompanyUpfrontSetting: 'setting/get-upfront-setting',
    updateCompanyUpfrontSetting: 'setting/add-update-upfront-setting-update',
    getCompanyReconciliation: '/setting/getReconciliationSchedule',
    deleteReconciliationPeriod: '/setting/deleteReconciliationSchedule',
    getCompanyGlobalReconciliation: '/setting/get-company-global-reconciliation',
    CompanyGlobalReconciliationsUpdate: '/setting/company-global-reconciliations-update',
    getMarkettingReconciliation: '/setting/get-marketing-reconciliation',
    updateMarkettingReconciliation: '/setting/marketing-reconciliation-update',
    getTierDuration: '/setting/get-tier-duration',
    updateReconciliation: '/setting/updateReconciliationSchedule',
    getSettingPayroll: '/setting/get-company-payrolls',
    getmarginofdiffrence: '/setting/get-margin-difference',
    updatemarginofdiffrence: '/setting/margin-difference-update',
    getLocationsList: '/setting/locations',
    exportLocationData: '/setting/export_location',
    deleteLocation: '/setting/locations',
    getCostCenterList: '/setting/cost-center-list',
    getCostCenterListbyID: '/setting/cost-center',
    getAllNotification: '/get_all_notifications',
    getSingleNotification: '/get_notification',
    getParentChildCostCenter: '/setting/parent-child-cost-center',
    getDepartmentList: '/setting/department',
    userListbyDepartmentId: 'setting/department-people',

    getPositionByDepartment: '/setting/get_data_position_from_department',
    getIncompleteAccountAlert: '/setting/get-incomplete-account-alert',
    getCompleteAccountAlert: '/setting/get-marketing-deal-alert',
    getAllPositionList: '/setting/get-all-position-commission',
    getPositionList: '/setting/position-commission',
    deletePositionPlan: '/setting/position-commission',
    addlocation: '/setting/locations',
    updatelocation: '/setting/update-locations',
    getStateList: '/setting/state',
    getRecuirter: '/hiring/recruiter_filter',
    getcostcenter: '/setting/cost-center-dropdown',
    getParentCostCenterList: '/setting/parent-cost-center',
    getAllStatesWithOffices: '/setting/get_all_state_with_office',
    getAllOfficeLocation: '/setting/AllUseLocation',
    upComingLocationRedlines: '/setting/getFutureRedLineByLocation',
    addcostcenter: '/setting/cost-center',
    updatecostcenter: '/setting/cost-center',
    getdartmentdropdown: '/setting/drop-down-department',
    adddepartment: '/setting/department',
    addposition: '/setting/add-position',
    updatePositions: '/setting/update-position',
    updatePosition: 'setting/position-commission',
    getposition: '/setting/position-dropdown',
    getPositionById: '/setting/position-commission',
    deletePositionDeduction: '/setting/delete_position_deduction',
    getApplicableoverridesV1: '/v1/management/applicableoverrides ',
    checkReconciliationSetting: '/setting/checkReconciliationSetting',
    createtier: '/setting/create-tier-configure',
    getPositionSetting: '/setting/positions_status',
    setupPosition: '/setting/position-commission',
    getdepartmentlist: '/setting/department-dropdown',
    deletelocation: '/setting/locations',
    disablecostcenter: '/setting/cost-center-disable',
    updatedepartment: '/setting/update-department',
    deletedepartment: '/setting/delete-department',
    getPositionListByDepartmentId: '/setting/positionByDepartmentID',
    reAssignManager: '/management/update-user-manager',
    getAllManagerlist: '/setting/managerList',
    getManagerByOfficeAndEffectiveDate: '/setting/managerList_by_effective_date',
    updatetierlevel: '/setting/tier-duration-level-update',
    getteamlist: '/setting/teams',
    updatecommission: '/setting/position-commission',
    deletetier: '/setting/delete-tier-configure',
    // Admin > Position
    userbyPositionID: '/setting/usersBypositionID',
    userListbyOfficeID: '/setting/usersByOfficeID',

    //Standard > onBoarding Employee
    addonbrdingemployee: '/hiring/onboarding_employee',
    getOnBoardingEmployeeList: '/hiring/onboarding_employee',
    exportOnBoardingEmployeeList: '/hiring/export_onboarding',
    deleteOnBoardingEmployeeList: '/hiring/delete_onboarding_employee',
    getonbordingemployeebyid: '/hiring/onboarding_employee',
    sendemailbyid: '/hiring/send_email_to_onboarding_employee',
    resendParticularDocument: 'hiring/resend_document_individually',
    updateonbordingemployee: '/hiring/onboarding_employee',
    addhiringleads: '/hiring/leads_list',
    addLeadWithoutAuth: '/add_lead_without_auth',
    checkDuplicateLead: '/hiring/check_status_email_and_mobile',
    checkDuplicateLeadWithoutAuth: '/check_status_email_and_mobile_without_auth',
    getRecentlyHiredList: '/hiring/recent_hired',
    getHiringProgressList: 'hiring/hiring_progress',
    getHiringProgressChart: '/hiring/hiring_graph',
    getHireLeadsList: '/hiring/leads_list',
    deleteLead: '/hiring/leads_list',
    postRescheduleInterview: '/hiring/interview_reschedule',
    getOrgChartById: '/setting/positionOrgChartByID',
    scheduleInterview: '/hiring/schedule_interview',
    getInterviewTimeSlot: '/hiring/schedule_time',
    changeLeadStatus: '/hiring/changeStatus',
    exportLeadsData: '/hiring/export_leads',
    importLeadsData: 'hiring/leads_import',
    hiringAssign: '/hiring/assign',
    getLeadComments: '/hiring/get_leads_comments',
    addLeadComments: '/hiring/add_leads_comments',
    addOnBoardingComments: '/hiring/add_employee_document_comment',
    replyLeadComments: '/hiring/add_lead_comment_reaply',
    getEmployeeDocumentComment: '/hiring/employee_document_comment_list',
    getLeadById: '/hiring/get_leadby_id',
    hireEmployeeDirectly: '/hiring/directHiredEmployee',
    getHiringCalendarEvents: '/CalenderEvent/hiring_event_list',
    getOnBoardingConfiguration: '/hiring/get_onboarding_configuration_employee',
    addOnBoardingConfiguration: '/hiring/onboarding_configuration_employee',
    addOrUpdateOnboardingDynamicAttributes: '/hiring/onboarding_configuration_employee_add',
    deleteOnboardingDynamicAttributes: '/hiring/onboarding_configuration_employee_delete',
    updateHireDateOfEmployee: '/hiring/hired_date_update',
    getEmployeeDataByCompensationType: '/hiring/get-employee-data-by-date-type',
    sendUserCredential: '/hiring/hiredEmployee',
    resendWelcomeEmail: '/hiring/welcome-mail',

    //Admin > Integrations
    getIntegtationList: '/setting/crm_setting_list',
    getIntegtationById: '/setting/get_crm_setting_by_id/',
    addIntegtationSetting: '/setting/crm_setting',
    updateIntegtationSetting: '/setting/crm_setting_update',
    connectDisconnectIntegtationSetting: '/setting/crm_setting_disconnect',
    getIntegrationLogs: '/reports/api_log_report',
    syncHubSpot: '/hubspotSyncData',

    //Standard > Onboarding Employees > Hire New
    hireNowStep1: '/hiring/onboarding_employee_details',
    hireNowStep2: '/hiring/onboarding_employee_originization',
    hireNowStep3: '/hiring/onboarding_employee_compensation',
    hireNowStep4: '/hiring/onboarding_employee_override',
    hireNowStep5: '/hiring/onboarding_employee_agreement',

    //Admin > Settings > Setup > Schedule Payroll
    getPayFrequencyType: '/setting/frequency-type-dropdown-list',
    updateCompanyPayroll: '/setting/Company-payroll-update',

    // Weekly  Pay Period
    getWeeklyPayPeriod: '/setting/weeklyPayFrequencyDropdown',
    getWeeklyExecutedPayPeriod: '/setting/weeklyPayFrequencyExecutedDropdown',

    // Monthly Pay Period
    getMonthlyPayPeriod: '/setting/monthlyPayFrequencyDropdown',
    getMonthlyExecutedPayPeriod: '/setting/monthlyPayFrequencyExecutedDropdown',

    // Bi Weekly
    getBiWeeklyPayPeriod: '/setting/bi-weekly-frequency-dropdown',
    getBiWeeklyExecutedPayPeriod: '/setting/bi-weekly-executed-frequency-dropdown',

    // Semi Monthly
    getSemiMonthlyPayPeriod: '/setting/semi-monthly-frequency-dropdown',
    getSemiMonthlyExecutedPayPeriod: '/setting/semi-monthly-executed-frequency-dropdown',

    // Admin > Settings > Alerts
    putToggleAlerts: '/setting/alert-enable-disable',
    postMarketingDeal: '/setting/alert-marketing-deal',
    createIncompleteAccountAlert: '/setting/alert-incomplete-account',
    getEmailNotificationSetting: 'setting/get_email_notification_setting',
    updateEmailNotificationSetting: 'setting/email_notification_setting',
    updateDomainSetting: '/setting/update_domain_setting',
    deleteDomainSetting: '/setting/delete_domain_setting',
    getDomainSetting: '/setting/get_domain_setting',
    addDomainSetting: '/setting/add_domain_setting',

    //Standard > Management
    getEmployeeListByLocation: '/management/managementEmployeeListByStateIds',
    getEmployeeList: '/management/managementEmployeeList',
    filterEmployeeList: '/management/management-employee-filter',
    addEmployeeDocumentById: '/management/AddDocumentBYUserId',
    updateEmployeeDocumentById: '/management/updateDocumentBYUserId',
    getEmployeeDocumentById: '/management/getDocumentBYUserId',
    getEmployeeSequiDocDocumentById: '/hiring/sequdocs_document_list_by_user_id',
    getOnBoardingEmployeeSequiDocDocumentById:
        '/hiring/sequdocs_document_list_by_onboarding_employees_user_id',
    deleteEmployeeDocumentById: '/management/deleteDocumentBYUserId',
    getDocumentTypeDropdown: '/management/document-type-dropdown',
    getEmployeeChart: '/management/employee_network',
    updateEmployeeUserGroup: '/permission/update_user_group',
    updateUserImage: 'management/updateUserImage',
    userNetworkOveride: '/management/my_overrides',
    overrideOnUser: '/management/mysale_overrides',
    addManualOverride: '/management/manual_overrides',
    addManualOverrideFrom: '/management/manual_overrides_from',
    getManualOverrideData: '/management/get_mysale_overrides',
    editManualOverrideFrom: '/management/edit_manual_overrides_from',
    editManualOverrideOn: '/management/edit_manual_overrides',
    changeOverrideStatus: '/management/my_overrides_enable_disable',
    exportEmployeeData: '/management/exportStandardEmployeeExport',

    //Audit log for employee
    getEmployeeAduitlogbyId: '/hiring/userProfileActivityLog',
    getAuditLogforallUser: '/activityLog',

    // Employee
    getEmployeeProfile: '/hiring/user-profile',
    getEmployeePersonalInfo: '/hiring/user-personal-info',
    getEmployeePackage: '/hiring/user-packege',
    getEmployeeBankingInfo: '/hiring/user-banking',
    getEmployeeTaxInfo: '/hiring/user-tax-info',
    updateEmployeeProfile: '/hiring/update-user-profile',
    updateEmployeePosition: '/hiring/update-user-position',
    updateEmployeeCommission: '/hiring/update-commission',
    getUserRedline: '/hiring/userRedlineHistory',
    updateUserAccountStatus: '/hiring/update-user-account-status',
    getUsersByManagerId: '/management/get-all-users-by-manger',
    transferEmployee: '/hiring/employee_transfer',

    //Admin > Sequifi Docs
    getSequiDocsTemplateCategories: '/hiring/category-dropdown-template',
    getSequiDocsTemplates: '/hiring/template',
    getSequiDocsTopTemplates: '/hiring/sequdocs_category_list_template_count',
    getTemplateHistory: '/hiring/docHistory',
    getSequifiDocTemplateHistory: '/hiring/sequiDoc_template_history',
    getSequiDocsTemplatesByCategory: '/hiring/category-dropdown-template',
    getSequiDocsAgreementList: '/hiring/get_agreements_list',
    getSequiDocsTemplateById: '/hiring/template',
    addSequiDocsTemplate: '/hiring/template',
    updateSequiDocsTemplate: '/hiring/template',
    deleteSequiDocsTemplate: '/hiring/template',
    searchSequiDocsTemplate: '/hiring/search-template',
    sendTemplate: '/hiring/generate-template',
    downloadTemplate: '/hiring/generate-template',
    assignTemplate: '/hiring/add-template-assign',
    getDocumentsList: '/management/DocumentList',
    getNewDocumentsList: '/hiring/sequdocs_document_list',
    addTemplateCategory: '/hiring/add_category_template',
    updateTemplateCategory: '/hiring/editCategoryTemplate',
    deleteSequiDocsCategory: '/hiring/deleteCategoryTemplate',
    deleteSequiDocsParticularTemplate: '/hiring/delete_template',
    addTemplateSettingStep1: '/hiring/add_sequdocs_template',
    updateTemplateSetting: '/hiring/update_template',
    addTemplateAgreementStep4: '/hiring/edit_sequdocs_template_agreements',
    addTemplateSignatureStep2: 'hiring/edit_sequdocs_template_signature',
    addTemplateEditTemplateStep3: '/hiring/edit_sequdocs_template',
    getTemplateListByCategory: '/hiring/sequdocs_template_list',
    getEmailTemplatesList: '/hiring/SequiDocsEmailTemplatesList',
    updateEmailTemplate: '/hiring/updateSequiDocsEmailTemplate',
    getTemplateDetail: '/hiring/sequdocs_template_detail',
    useOrSendSequiDocTemplate: '/hiring/sequdocs_template_usenow',
    useSequiDocsTemplate: '/hiring/sequdocs_send_document/Use',
    testParticularTemplate: '/hiring/sequdocs_send_document/Test',
    downloadParticularTemplate: '/hiring/sequdocs_send_document/Download',
    sendSequiDocTestTemplateEmail: '/hiring/sequdocs_test_template',
    getSmSequiDocData: '/hiring/sequdocs_standerd_template_list',
    searchEmployeeToSendDoc: '/hiring/employee_search_for_send_document',

    //manager > Report
    getManagerReport: 'managerreports/manager_report_office',
    getMySalesReportV1: '/reports/v1/sales_report',
    getMySalesChart: '/sales/my_sales_graph',
    getReconcilliationReport: '/managerreports/reconciliation',
    getSaleTracking: 'sales/customerSaleTracking',
    getSalesReport: '/managerreports/report_sales_list',
    getSalesGraph: '/managerreports/report_sales_graph',
    getSalesAccountAndInstallRatioGraph: '/managerreports/report_account_install_ratio_graph',

    //Admin Report
    getReconciliationReport: '/reports/reconciliationPayrollHistoriesList',
    exportReconciliationReport: '/reports/exportReconciliationPayrollHistoriesList',
    exportPayrollReconciliationReport: '/reports/exportPayrollReconciliationList',
    getSingleReconcliationReport: '/reports/payrollReconciliationList',
    getAdminAllGraphsV1: '/v1/reports/sales_graph',
    exportAdminSales: '/reports/sales_export',
    getAdminCustomerListV1: '/v1/reports/sales_report',
    getAdminCostGraph: '/reports/costs_graph',
    getAdminCostReport: '/reports/costs_report',
    getAdminClawBackReport: '/reports/claw_back',
    getAdminPayrollReportV1: '/v1/reports/payroll_report',
    getAdminPayrollReportByYearMonthAndFrequency: '/reports/get_report_year_month_frequency_wise',
    getAdminPayrollByFiltersV1: '/v1/payroll/get_report_year_month_frequency_wise',
    getAdminPendingInstallReport: '/reports/pending_install',
    exportAdminPendingInstallReport: '/reports/export_pending_install',
    getAdminCompanyReport: '/reports/company_report',
    getAdminCompanyGraph: '/reports/company_graph',
    exportPayrollReport: '/payroll/export_payroll_report',
    exportCostData: '/reports/costs_export',
    exportAdminClawBackReport: 'excel/clawbackSalesExport',
    payrollReconciliation: '/payroll/payroll_reconciliation_details',
    getAdminSalesAccountData: 'v1/reports/sales_account',
    getAdminSalesContractsData: '/v1/reports/sales_contracts',
    getAdminSalesContractsIRData: '/v1/reports/sales_install_ratio',
    getAdminSalesAvarageDatas: '/v1/reports/sales_best_avg',

    // Requests & Approval
    getAdjustmentTypeList: '/RequestApproval/adjustment-type',
    getAlLRequestsList: '/RequestApproval/request-approval',
    searchRequest: '/RequestApproval/request-approval',
    getRequestDetailsByRequestNumber: '/RequestApproval/getRequestApprovalStatusByReq_No',
    updateStatusOfRequest: '/RequestApproval/update-status-of-request',
    addRequest: '/RequestApproval/request-approval',
    addRequestApprovalComment: '/RequestApproval/requestApprovalComment',
    getAllApprovalList: '/RequestApproval/approval-list',
    searchApproval: '/RequestApproval/search-approval',
    approvalHistory: '/RequestApproval/approvalListForHistory',

    //Admin Permmission
    getGroupList: '/permission/group_list',
    getPoliciesList: '/permission/policies_list',
    deletePermission: '/permission/delete_permission',
    getPermission: '/permission/get_permission',
    addGroupPermission: '/permission/add_group_permission',
    updateGroupPermission: '/permission/update_group_permission',
    groupPolicyList: '/permission/group_policies_list',
    getListofGroup: '/permission/group_by_user_list',

    //SM > My Sales
    getMySalesGraph: '/sales/my_sales_graph',
    // getMySalesList: '/sales/sales_list',
    getMySalesListV1: '/v1/sales/sales_list',
    getMySalesAccountInitialRatioGraphV1: '/v1/sales/account_install_ratio_graph_api',
    getMySalesOverrideData: '/sales/getOverrides',
    getMyRecalculateData: '/sales/recalculate_sale_data',
    getSaleUserRedlines: '/sales/get_user_redlines',
    getGeneralCodeByStateId: '/setting/getGeneralCodeListByStateID',

    //Closer and Setter
    getSalesCloserList: '/setting/get-closer',
    getSalesSetterList: '/setting/get_setter_dropdown',
    getSalesByPid: '/reports/sales_by_pid',
    updateSetterByPid: '/setting/update-setter-by-pid',
    updateCloserByPid: '/setting/update_pid_with_closer',
    getCloserSetterByEffectiveDate: '/setting/setterCloserListByEffectiveDate',

    // Admin > Alert Center
    // getAlertCenterList: '/setting/alert-center',
    getAlertCenterList: '/setting/alert_center_details',
    getMissingDataAlertCenter: '/setting/sales-detail-by-pid',
    updateMissingDataAlertCenter: '/setting/update-alert-center-data',
    updateMissingPayPeriod: '/setting/updatePayRollMissingPeriod',
    getAdderByPidAlertCenter: '/setting/adders_by_pid',
    getClawbackByPidAlertCenter: '/setting/adjust_clawback_sale_data',
    getPayrollByidAlertCenter: '/payroll/addAlertToPayroll',
    getPayRollMissingDetails: '/setting/payRollMissingDetailByPid',
    getAllAlertMissingData: '/setting/payRollMissingDetailByPid',
    getSalesAndMissingRepMissingDetail: '/setting/getMissingDetailByPid',
    searchAllAlertData: '/reports/global_search',
    refreshAlertCenterDetails: '/setting/refresh_alert_center_details',

    //Admin > Settings > POsition update
    updatePositionHierarchy: '/setting/update_position_hierarchy',
    updatePositionGroup: '/setting/update-position-group',
    positionOrgChart: '/setting/positionOrgChart',

    //Standard > Calander > Calander Event
    addCalanderEvent: '/CalenderEvent/add-event',
    getCalanderEventList: '/CalenderEvent/event-list',
    deleteCalanderEvent: 'CalenderEvent/delete',
    updateCalanderEvent: '/CalenderEvent/edit_event',
    getGlobalSheduleTimeCalendar: '/hiring/schedule_time_slot',
    setSheduleTimeSlot: '/hiring/user_schedule_time',
    getUserSheduleTime: '/hiring/get_user_schedule_time',

    //Standard > Reports > Sales > Account Overrides
    getAccountOverridesbyPid: '/sales/account_overrides',

    //Sm > management > Teams
    getManagmentTeams: '/management/management-team',
    getTeamLeadByOfficeId: '/setting/getUserByOfficeIDForTeamLead',
    getTeamMembersByOfficeId: '/setting/getUserByOfficeIDForTeamMember',
    deleteManagmentTeamMember: '/management/delete-management-team-member',
    deleteTeam: '/management/delete_team',
    addManagementTeam: '/management/management-team',
    updateManagementTeam: '/management/update-management-team',

    // Admin > Reports > Sales > Add Sales
    addSale: '/setting/add_manual_sale_data',
    salesAccountSummaryData: '/reports/salesAccountSummary',
    salesAccountSummarybyPosition: '/reports/salesAccountSummaryByPosition',
    getCompanyAccountSummary: '/reports/company_margin_summary',
    getSaleRedlinesUserWise: '/sales/get_user_wise_redlines',
    getSaleRedlinesFromPID: '/sales/get_user_redlines',
    getProjectionSummaryV1: '/v1/sales/user_projection_summary',

    //   Get Super Admin Access
    getSaleDataForSuperAdmin: '/setting/get_sales_data_by_super_admin',

    // Payroll
    getPayrollDataV1: '/v1/payroll/payroll_data',
    getPayrollHistory: '/payroll/get_summary_payroll',
    getPayrollHistoryV1: '/v1/payroll/get_summary_payroll',
    getReportPayrollHistory: '/reports/get_report_summary_payroll',
    getCommissionDetailsV1: '/v1/payroll/commission_details',
    payrollMarkAsPaidV1: '/v1/payroll/single_payroll_mark_as_paid',
    singleMoveToNextPayrollV1: '/v1/payroll/single_payroll_move_next_payroll',
    getReportCommissionDetailsV1: '/v1/reports/commission_details',
    getPaystubCommissionDetails: '/managerreports/commission_details',
    getReimbursementDetailsV1: '/v1/payroll/reimbursement_details',
    getOneTimePaymentReimbursementDetails: '/payroll/one_time_reimbursement_details',
    getOneTimePaymentAdjustmentDetails: 'payroll/one_time_adjustment_details',
    getReportReimbursementDetailsV1: '/v1/reports/reimbursement_details',
    getReportReconciliationDetails: '/payroll/runPayrollReconciliationPopUp',
    getPaystubReimbursementDetails: '/managerreports/reimbursement_details',
    getOverrideDetailsV1: '/v1/payroll/override_details',
    getReportOverrideDetailsV1: '/v1/reports/override_details',
    getPaystubOverrideDetails: '/managerreports/override_details',
    editOverrideDetails: '/payroll/payroll_overrides_edit',
    editCommissionDetails: '/payroll/payroll_commission_edit',
    editReconCommissionDetails: '/payroll/reconciliation_details_edit',
    editReconOverrideDetails: '/payroll/reconciliation_overrides_details_edit',
    editDeductionDetails: '/payroll/payroll_deduction_edit',
    getAdjustmentDetailsV1: '/v1/payroll/adjustment_details',
    getReportAdjustmentDetailsV1: '/v1/reports/adjustment_details',
    getPaystubAdjustmentDetails: '/managerreports/adjustment_details',
    deleteAdjustmentDetails: '/payroll/delete_adjustement',
    singleAdjustmentDeleteV1: '/v1/payroll/single_payroll_adjustment_delete',
    deleteAdjustmentReconDetails: '/payroll/deleteReconAdjustement',
    getPaymentRequestDetails: '/payroll/payment_request',
    payNowPaymentRequest: '/payroll/paymentRequestPayNow',
    getAdvancePaymentRequestDetails: '/payroll/advance_payment_request',
    updatePaymentRequestV1: '/v1/payroll/update_payment_request',
    getReconciliationDetail: '/payroll/reconciliation_details',
    getPayrollDeductionDetail: '/payroll/payrollDeductionsByEmployeeId',
    getPaystubDeductionDetail: '/managerreports/deduction_details',
    updateReconciliationDetail: '/payroll/reconciliations_adjustment',
    payrollReconciliationHistory: '/payroll/reconciliationFinalizeDraft',
    reconciliationFinalizeDraftList: '/payroll/reconciliationFinalizeDraftList',
    oneTimePayment: '/payroll/one_time_payment',
    oneTimePaymentHistory: '/payroll/get_onetime_payment_history',
    onTimePaymentTotal: '/payroll/onetime_payment_total',
    exportOneTimePaymentHistory: '/payroll/export_payment_history',
    finilizeReconciliation: '/payroll/finalizeReconciliationList',

    sendToPayrollRecon: '/payroll/sendToPayrollRecon',
    getFinilizePayroll: '/payroll/get_finalize_payroll',
    getFinalizePayrollStatus: '/payroll/finalize_status_Payroll',
    finilizePayrollV1: '/v1/payroll/finalize_Payroll',
    executePayrollV1: '/v1/payroll/execute_Payroll',
    updatePayrollDetail: '/payroll/update_payroll',
    adjustPayrollDetail: '/payroll/payroll_adjustment',
    moveToNextPayrollV1: '/v1/payroll/payroll_move_next_payroll',
    markAsPaidPayrollV1: '/v1/payroll/payroll_mark_as_paid',
    moveToReconciliationPayroll: '/payroll/moveRunPayrollToReconStatus',
    payrollReconClawbackPopUp: '/payroll/payrollReconClawbackListbyEmployeeId',
    payrollReconAdjustmentPopUp: '/payroll/payrollReconAdjustementbyEmployeeId',
    payrollReconOverridePopUp: '/payroll/payrollReconOverridebyEmployeeId',
    payrollReconCommissionPopUp: '/payroll/payrollReconCommissionbyEmployeeId',
    reportReconClawbackPopUp: '/reports/reportReconClawbackListbyEmployeeId',
    reportReconAdjustmentPopUp: '/reports/reportReconAdjustementbyEmployeeId',
    reportReconOverridePopUp: '/reports/reportReconOverridebyEmployeeId',
    reportReconCommissionPopUp: '/reports/reportReconCommissionbyEmployeeId',
    closePayroll: '/payroll/close_payroll',
    undoReconPayroll: '/payroll/payrollsReconciliationRollback',
    undoPaidPayrollV1: '/v1/payroll/payroll_mark_as_unpaid',
    undoMoveToNextPayrollV1: '/v1/payroll/payroll_undo_next_payroll',

    //payroll export
    exportWorkerBasicPayrollDetail: '/payroll/workker-basic',
    exportWorkerDetailsPayrollDetail: '/payroll/workker-detail',
    exportWorkerAllDetailsPayrollDetail: '/payroll/worker-all-details',
    exportPidDetailsPayrollDetail: '/payroll/pid-detail',
    exportPidBasicPayrollDetail: '/payroll/pid-basic',

    //Report export
    exportWorkerBasicReportsDetail: '/reports/workker-basic',
    exportWorkerDetailsReportsDetail: '/reports/workker-detail',
    exportWorkerAllDetailsReportsDetail: '/reports/worker-all-details',
    exportPidDetailsReportsDetail: '/reports/pid-detail',
    exportPidBasicReportsDetail: '/reports/pid-basic',

    // Payroll Reconcililiation office dropdown
    getReconOfficeDropdownList: '/setting/recon-office-dropdown',
    getReconPositionDropdownList: '/setting/recon-position-dropdown',
    skippedReconList: '/payroll/ReconciliationListUserSkipped',
    undoSkippedReconList: '/payroll/ReconciliationListUserSkippedUndo',

    //Onboarding Employees>After login
    getOnBoardingEmployeeDetail: '/hiring/get_onboarding_employee',
    updateOnBoardingEmployeeDetail: '/hiring/update_onboarding_employee',
    changeOnboardingEmployeeStatus: '/hiring/onBoardingChangeStatus',
    changeOnboardingEmployeeAgreementStatus: '/hiring/accept_decline_agreement',
    getOnboardingConfiguration: '/hiring/get_onboarding_configuration_employee',
    uploadOnBoardingDocument: '/management/AddDocumentBYUserId',
    deleteOnBoardingDocument: '/management/deleteDocumentBYUserId',

    //admin setup payFrequency
    getPayFrequencySetting: '/setting/listPayfrequencySetting',
    addPayFrequencySetting: '/setting/AddPayfrequencySetting',

    //New Employee Package
    getuserEmploymentPackageByid: '/hiring/user-detail-by-id',
    updateUserPersonalData: '/hiring/user_details',
    updateUserOrganizationData: '/hiring/user_organization',
    updateUserDeductionData: '/hiring/update_user_deduction',
    updateUserOverrideData: '/hiring/user_override',
    updateUserAgreementData: '/hiring/user_agreement',
    updateUserCompensation: '/hiring/user_compensation',
    getOrganizationDetail: '/hiring/combine_organization_history',
    getTransferHistory: '/hiring/combine_transfer_history ',
    getOverrideHistory: '/hiring/combine_override_history',
    getRedlineUpfrontHistory: '/hiring/combine_redline_commission_upfront_history',
    getDeductionHistory: '/hiring/combine_deduction_history',
    //Search API
    userByOfficeId: '/setting/getUserByOfficeID',

    //Admin Dashboard API
    getDashboardPayrollSummary: '/dashboard/admin/get_payroll_summary',
    getTopPayrollLocations: '/dashboard/adminTopPayRollLocation',
    getAnnouncementList: '/dashboard/get_list_announcement',
    getAnnouncementCardData: '/dashboard/get_standard_announcement_card',
    getAlertList: '/dashboard/dashboard_alert_center',
    getHiringAlertList: '/hiring/alertScheduleInterview',
    getDashboardOfficeSalesPerformance: '/dashboard/adminOfficePerformance',
    getOfficeSalesPerformanceGraph: '/dashboard/officePerformanceSelesKw',
    getSmDashboardSalesReport: '/dashboard/salesReport',
    getSmOfficeSalesPerformance: '/dashboard/officePerformance',
    getSmOfficePeformanceTeam: '/dashboard/officePerformanceSelesTeam',
    getGoalTrackerData: '/dashboard/goalsTracker',
    setGoalTracker: '/dashboard/setGoals',
    getSmOfficeEventList: '/dashboard/event_list',
    addAnnouncement: 'dashboard/add_list_announcements',
    disableAnnouncement: '/dashboard/announcement_disable',
    deleteAnnouncement: 'dashboard/delete_list_announcements',

    //Standard dashboard
    getDashboardActionItemsList: '/dashboard/dashboard_item_section',
    dashboardActionItemStatusChange: 'dashboard/action_item_status_change',

    //Past pay stub
    getPastPaystubGraphData: '/managerreports/past_pay_stub_graph',
    getPastPaystubTableData: '/managerreports/past_pay_stub',
    getPastPaystubDetail: '/managerreports/past_pay_stub_detail',
    getPastPaystubDetailList: '/managerreports/past_pay_stub_detail_list',
    getPastPaystubDetailListV1: '/v1/managerreports/past_pay_stub_detail_list',
    getCurrentPaystubCustomerDetail: '/managerreports/current_pay_stub_customer_info',
    getCurrentPaystubDetail: '/managerreports/current_pay_stub_detail_list',
    getPastPaystubUserDetail: '/managerreports/past_pay_stub_customer_info',
    getOneTimePaymentUserDetail: '/payroll/one_time_payment_pay_stub_list',
    getSingleOneTimePaymentUserDetail: '/payroll/one_time_payment_pay_stub_single',

    //Filters
    locationFilter: '/setting/location_filter',
    positionFilter: '/setting/position-commission',
    leadsFilter: '/hiring/leads_list',
    getAllReportingManagerList: '/hiring/reporting_manager',
    runPayrollFilter: '/payroll/payroll_data',
    salesReportFilter: '/managerreports/report_sales_list',

    //Standard Request and Approval
    getPidByUser: '/sales/getPidByUser',
    exportApprovalHistory: '/RequestApproval/exportRequestApprovalHistory',

    //Employment Package History
    getEmploymentPackageHistory: '/hiring/get_employment_package_history',
    deleteEmploymentPackageHistory: '/hiring/delete_employment_package_history',
    deleteManualOverrideHistory: '/management/delete_manual_overrides',

    // Setting UserManagement

    getUserManagementList: 'management/userManagementList',
    addNewAdmin: '/hiring/add_admin',
    makeSuperAdmin: '/management/make_user_group_admin',
    suspendAccessOfUser: '/management/suspend_user_access',
    exportUserManagementList: 'management/export-user-management',

    // Setting Billing Details
    getOverdueBillingStatus: '/get_billing_status',
    getBusinessAddressList: '/setting/get-business-address',
    updateBusinessAddress: '/setting/update-business-address',
    getSubscriptionList: '/get_subscriptions',
    manageSubscription: '/manage_subscription',
    getBillingHistoryData: '/get_billing',
    getSubscriptionPidData: '/get_pidsdata',
    getBillingPidData: '/get_Sales_Invoice_pids',
    getBillingM2Data: '/get_Sales_Invoice_m2_data',
    getBillingAdjustedPidData: '/get_Sales_Invoice_adjusted_kw_data',
    getSubscriptionM2CompleteData: '/get_m2data',
    getSubscriptionAdjustedPidData: '/getadjustedkwdata',
    getSubscriptionPayrollChecks: '/get_billing_payroll_data',
    getBillingPayrollChecks: '/get_invoice_payroll_data',
    getSubscriptionOneTimePayment: '/get_billing_one_time_payment_data',
    getBillingInvoice: '/get_invoice_data',
    getInvoiceOneTimePaymentData: '/get_invoice_one_time_payment_data',
    getActiveUserBilling: '/get_users_data',

    getBillingPaymentGatewayInfo: '/add_payment',
    paymentCallback: '/stripe_callback_url',
    getStripePaymentMethods: '/listpaymentmethods',
    updateStripeDefaultPaymentMethod: '/updatepaymentmethod',
    updateAutoPayPaymentSetting: '/updateautopayment',
    deleteStripePaymentMethod: '/deletepaymentmethod',
    getClientIntent: '/setup_intents',

    importAdminSalesData: '/reports/sales_import',
    importOnboardingUsersSalesData: '/excel/excel_import_onboarding_user',
    importAdminSalesHistoryData: '/excel/get_excel_import_list',
    importOnboardingUsersHistoryData: '/excel/get_user_excel_import_list',
    downloadRequiredFilesForImportOnboardingUsers: '/download_user_separate_sheet',

    emailSetting: '/email_configuration',
    testEmailSetting: '/email_configuration_check',
    getEmailSetting: '/email_configuration_list',

    getUserAdditionalEmails: '/hiring/users_additional_emails_list',
    deleteUserAdditionalEmail: '/hiring/delete_users_additional_email',
    addUpdateUserAdditionalEmail: '/hiring/add_users_additional_emails',

    // Import Excel Templates
    getImportExcelTemplateList: '/reports/import-template-list',
    saveImportExcelTemplate: '/reports/import-template-save',
    deleteImportExcelTemplate: '/reports/import-template-delete',

    // Ticket System Module
    getTicketList: '/ticket',
    deleteTicketbyId: '/ticket/delete',
    createTicket: '/ticket/store',
    getModuleDropdownList: '/ticket/module/dropdown',
    getParticularTicketbyId: '/ticket/view',
    getFaQList: '/ticket/faqs',

    getTopTopicList: '/ticket/confluence',
    getParticularTopicData: '/ticket/confluence/view',
    // search
    getTopicSearchSuggestions: '/ticket/confluence/dropdown/filter',

    //Sclearance
    getSclearanceUserListByOfficeAndPositionV1:
        '/v1/setting/s_clearance/office_and_position_wise_user_list',
    newInternalRecipientV1: '/v1/setting/s_clearance/new_clearance_internal',
    newExternalRecipientV1: '/v1/setting/s_clearance/new_clearance_external',
    getConfigurationsV1: '/v1/setting/s_clearance/get_configurations',
    addConfigurationsV1: '/v1/setting/s_clearance/add_configurations',
    getScreeningRequestsListV1: '/v1/setting/s_clearance/get_all_screening_requests_list',
    deleteScreeningRequestV1: '/v1/setting/s_clearance/cancel_screening_requests',
    getUpdaterReportV1: '/v1/setting/s_clearance/view_report_details',
    updateReportStatusV1: '/v1/setting/s_clearance/approve_decline_bv_report',
    getUserListByOfficeAndPosition: '/new_sequi_docs/office_and_position_wise_user_list',
    sClearanceReportPusherV1: '/v1/s-clearance/callback-url/report',
    sClearanceManualPusherV1: '/v1/s-clearance/callback-url/manualauthentication',
    sClearanceReportV1: '/v1/s_clearance/getapplicantreport',
    resendEmailV1: '/v1/setting/s_clearance/resend_sclearance_request',
    addNewSclearanceV1: '/v1/setting/s_clearance/add_new_sclearance',

    //Integrations
    GetPlanListsV1: '/v1/setting/s_clearance/get_plans',
    getSequiAiPlans: '/v1/sequiai/get_plans',
    getActivePlanV1: '/v1/s_clearance/get_plan',
    activateSClearanceV1: '/v1/setting/crm_setting',
    activateSequiAi: '/v1/setting/sequai_crm_setting_update',
    getSclearanceEmployeDetailsbyIdV1: '/v1/setting/s_clearance/get_employer',
    updateUserProfileSclearanceV1: '/v1/setting/s_clearance/update_employer',
    disableSclearanceV1: '/v1/setting/crm_setting_disconnect',
    getSelectedSclearancePlanbyIdV1: '/v1/setting/get_crm_setting_by_id', // this will provide deatils of existing plans
    updateSclearancePlanV1: '/v1/setting/crm_setting_update', // To update existing plans
    getConfigByPositionV1: '/v1/setting/s_clearance/get_configurations_position_based',
    getBackgroundCheckUserDetailV1: '/v1/s_clearance/get_user_details',
    updatPersonalDetailsforBackgroundCheckV1: '/v1/s_clearance/add_screening_request',
    getBackgroundVerificationQuestionsV1: '/v1/s_clearance/background_verification_exam',
    submitBackgroundVerificationAnswersV1: '/v1/s_clearance/background_verification_exam_answers',
    validateRequestV1: '/v1/s_clearance/validate_request',
    sClearanceBillingReportV1: '/v1/s_clearance/users_billing_report',
    //New sequiDoc
    getNewSequiDocsMainTemplates: '/new_sequi_docs/new_sequdocs_offer_letter_and_agreement_count',
    getNewParticularTemplateList: '/new_sequi_docs/new_sequdocs_template_list',
    getUnAssignedOfferLetterList: '/new_sequi_docs/no_offer_letter_position_list',
    addNewTemplateSettingStep1: '/new_sequi_docs/add_new_template',
    checkOfferLetterDuplicateRecipient: 'new_sequi_docs/check_position_has_offer_letter',
    newSequiDocTemplateDetailById: '/new_sequi_docs/new_sequdocs_template_detail_by_id',
    newSequiDocUpdateTemplate: '/new_sequi_docs/update_new_template',
    newSequiDocEditTemplateStep2: '/new_sequi_docs/save_new_template_content',
    newSequiDocAgreementList: '/new_sequi_docs/get_new_sequdocs_agreements_list',
    newSequiDocAdditionalDocumentList:
        '/new_sequi_docs/get_new_sequdocs_additional_agreements_list',
    newSequiDocForHiringStep3: '/new_sequi_docs/save_onboarding_employee_hiring_agreements',
    newSequiDocForPostHiringStep4:
        '/new_sequi_docs/save_onboarding_employee_post_hiring_agreements',
    uploadSequiDocPdfDocument: '/new_sequi_docs/upload_dcoument_pdf',
    getUploadedSequiDocPdfDocument: '/new_sequi_docs/get_upload_dcoument_pdf',
    getNewSequiDocAdditionalDocumentList:
        '/new_sequi_docs/new_sequdocs_additional_agreement_document_list',
    getUserListByOfficeAndPosition: '/new_sequi_docs/office_and_position_wise_user_list',

    //My override
    overridesBreakdownV1: '/v1/management/my_overrides_cards',
    overrideListV1: '/v1/management/list_overrides',
    overrideGraphV1: '/v1/management/my_overrides_graph',
    generateOverrideV1: '/v1/management/generate_user_override',

    // Pipeline
    //Leads
    getPipelineLeadStatusV1: '/v1/pipeline/list_lead_status', // Bucket name and childs are here
    updateLeadStatusV1: '/v1/pipeline/update_lead_status',
    updatePipelineBucketV1: '/v1/pipeline/update_lead_card',
    addAndUpdateBucketsV1: '/v1/pipeline/add_update_lead_status',
    getPipelineHistoryV1: '/v1/pipeline/history_lead_status',
    getPipelineCommentsV1: '/v1/pipeline/list_lead_comment',
    addPipelineCommentsV1: '/v1/pipeline/add_lead_comment',
    getInterviewerV1: '/v1/pipeline/get_take_interviews_list',

    //Onboarding
    getOnboardingBucketDataV1: '/v1/pipeline/list_onboarding_status',
    toggleBucketVisibilityV1: '/v1/pipeline/show_hide_onboarding_status',
    getTemplateCategoryListForDropdown: '/new_sequi_docs/template_category_dropdown',
    getTemplateListForDropdown: '/new_sequi_docs/category_id_wise_template_list_dropdown',
    testSmartTextTemplate: '/new_sequi_docs/test_smart_text_template',
    sendTestOtherBlankTemplate: '/new_sequi_docs/test_other_blank_template',
    sendTestOtherPdfTemplate: '/new_sequi_docs/test_other_pdf_template',
    sendOrTestDocumentToUpload: '/new_sequi_docs/test_and_send_document_to_upload_files',
    sendTestOfferLetterTemplate: '/new_sequi_docs/offer_letter_category_test_template',
    sendOfferLetterTemplate: '/new_sequi_docs/offer_letter_category_use_template',
    sendNowOtherTemplates: '/new_sequi_docs/use_other_blank_template',
    sendNowOtherPdfTemplates: '/new_sequi_docs/use_other_pdf_template',
    sendNowSmartTextTemplate: '/new_sequi_docs/use_smart_text_template',
    deleteNewSequiDocsTemplate: '/new_sequi_docs/delete_sequdocs_template',
    finishUploadPdfTemplate: '/new_sequi_docs/finish_upload_dcoument_pdf',
    removeAndReassignPositionToTemplate: '/new_sequi_docs/remove_and_reassign_position_to_template',
    getSequiDocDocumentToUplodList: '/new_sequi_docs/get_new_sequi_docs_document_type_list',
    createNewDocumentToUploadType: 'new_sequi_docs/create_new_sequi_docs_document_type',
    newSequiDocEmployeeDocumentList: '/new_sequi_docs/new_sequdocs_document_list',
    newSequiDocEmployeeDocumentViewList:
        '/new_sequi_docs/category_wise_document_list_with_user_count',
    newSequiDocPeopleListByTemplate: '/new_sequi_docs/get_signed_documents_user_details',
    testNewSequiDocTemplateAfterCreation: '/new_sequidoc_template_testing',
    newSequiDocTemplateHistory: '/new_sequi_docs/new_sequiDoc_template_history',
    getPostDocumentVersionList: '/new_sequi_docs/document_version_list',

    //Signign Section

    validatePasswordForLogin: '/hiring/digital-signature/validate-password',
    getAllDocumentsToSign: '/hiring/digital-signature/get-all-pdf-doc',
    getSingleDocumentToSign: '/hiring/digital-signature/get-pdf',
    getCoordinatedOFTextFromDoc: '/hiring/digital-signature/get-smart-text-location',
    getAllSignedDocuments: '/hiring/digital-signature/get-processed-pdf',
    getDocumentToUploadForSign: '/upload_to_document_list_for_signing_screen',
    uploadFilledSignedDocument: '/hiring/digital-signature/visible_signature/store',
    // applyFilledDataToDocument: '/hiring/document-digital-signature/apply',
    applyFilledDataToDocument: '/hiring/document-digital-signature/apply-data',
    applyESignToDocument: 'hiring/document-digital-signature/apply-e-sign',
    applyDigitalSignToDocument: '/hiring/document-digital-signature/apply-digi-sign',

    downloadSignedDocument: '/hiring/digital-signature/pdf/download',

    getDownloadPathForUploadedDocument: '/get_complete_s3_file_path',
    uploadPreHiringDocumentInSigning: '/new_sequidoc_manual_file_upload',
    deletePreHiringDocumentInSigning: '/new_sequidoc_delete_manual_file_upload',
    requestChangeForOfferLetter: '/new_sequidoc_raise_change_request',
    rejectOfferLetter: '/new_sequidoc_reject_signing',
    getNewSequiDocDocumentAndOtherDetails: '/offer_letter_status_and_other_details',

    //After Hiring Section
    getUserNewSequiDocDocumentList: '/new_sequi_docs/new_sequdocs_document_list_by_user_id',
    newSequiDocPostHiringDocumentList: '/new_sequi_docs/users_post_hiring_document_list',
    newSequiDocOnBoardingUserDocumentList:
        '/new_sequi_docs/new_sequdocs_document_list_by_onboarding_employee_user_id',

    //OnBoarding Process
    sendOfferLetterMailToOnBoardingEmployee:
        '/new_sequi_docs/send_offer_letter_to_onboarding_employee',

    reSendNewSequiDocParticularDocument: 'new_sequi_docs/new_sequidoc_resend_document_individually',
    reSendNewSequiDocParticularDocumentToUpload: '/new_sequi_docs/re_send_document_to_upload_files',
    getNewSequiDocDocumentComments: '/new_sequi_docs/get_comments_on_users_documents',
    addNewSequiDocDocumentComments: '/new_sequi_docs/add_comment_on_user_document',
    newSequiDocOnBoardingUserById: '/hiring/onboarding_employee_details_by_id',
    newSequiDocOnBoardingUsers: '/hiring/onboarding_employee_listing',

    //External Recepient
    sendDocumetToExternalEmplopyee: '/new_sequi_docs/send_document_to_external_recipient',
    sendDocumetToUploadToExternalEmplopyee:
        '/new_sequi_docs/test_and_send_document_to_upload_files_to_external_recipient',
    getExternalEmplopyeeDocumentList: '/new_sequi_docs/document_list_for_external_recipient',
    getExternalEmplopyeeProfile:
        '/new_sequi_docs/new_sequdocs_document_list_by_external_recipient_email',
    updateExternalEmplopyeeName: '/new_sequi_docs/new_sequdocs_update_external_recipient_name',

    //Standerd SequiDoc list
    getStanderdNewSequiDocTemplateList: '/new_sequi_docs/new_sequdocs_standerd_template_list',

    // Pending pay
    getPendingPayV1: '/v1/sales/pending_pay',
    getPendingPayCommisssionV1: '/v1/sales/commission_details',
    getPendingPayOverrideV1: '/v1/sales/override_details',
    getPendingPayAdjustmentV1: '/v1/sales/adjustment_details',
    getPendingPayDeductionsV1: '/v1/sales/payrollDeductions',
    getPendingPayReconciliationV1: '/v1/sales/PayrollReconciliation',
    getPendingPayReimbursementV1: '/v1/sales/reimbursementDetails',
    getPendingPayAdditionalValues: 'v1/sales/additional_value_details',

    // Excel import
    getPayollSettingV1: '/v1/setting/get_payroll_setting',
    addPayrollSettingV1: '/v1/setting/add_payroll_setting',
    deletePayrollSettingV1: '/v1/setting/delete_payroll_setting',
    getCustoFieldV1: '/v1/payroll/get_custom_filed',
    updateCustomFieldV1: '/v1/payroll/update_payroll_custom_filed',
    getAdditionalValueDetailsV1: '/v1/reports/additional_value_details',
    exportCustomFieldsV1: '/v1/payroll/export_custom',
    importCustomFieldsV1: '/v1/payroll/import_custom',

    //SequiAI
    getOpenAIChatV1: '/v1/sequiai/suggestion',

    //Advance payment request
    updateAdvanceSetting: '/setting/advance-payment-setting',
    getAdvanceSetting: '/setting/get-advance-payment-setting',
    getRepaymentDetails: '/payroll/advance_negative_payment_request',
    advancePayment: '/payroll/advance_repayment',

    //
    addNewDocumentToProfile: '/upload_manual_document_from_user_profile',

    //SalesCRM
    getSalesCRMPipelineV1: '/v1/bucket/list_buckets_with_jobs',
    addUpdateSalesCRMBucketV1: '/v1/bucket/add_update_bucket',
    deleteSalesBucketV1: '/v1/bucket/deletebucket',
    moveSalesJobV1: '/v1/bucket/move_job',
    addSalesSubTaskV1: '/v1/bucket/add_bucket_subtask',
    updatePreferencesV1: '/v1/bucket/user_preference_update',
    deleteSubTaskV1: '/v1/bucket/deletesubtask',
    deleteSalesJobV1: '/v1/bucket/jobinfoupdate',
    getBucketSubTaskV1: '/v1/bucket/getbucketsubtasks',
    addSalesJobV1: '/v1/crmsale/addcrmjob',
    getBucketTaskInfoV1: '/v1/crmsale/buckettaskinfo',
    getMoveBucketListV1: '/v1/crmsale/listbucketmovejob',
    getAllBucketV1: '/v1/bucket/list_buckets',
    getSalesCRMPreferenceV1: '/v1/bucket/getuserpreference',
    updateSubtaskV1: '/v1/bucket/subtaskupdate',
    addSalesDocumentV1: '/v1/crmsale/adddocuments',
    getSalesDocumentsV1: 'v1/crmsale/getdocuments',
    addSaleCommentsV1: '/v1/crmsale/add_comment',
    getSalesCommentV1: '/v1/crmsale/getcomments',
    deleteAttachmentV1: '/v1/crmsale/deleteattachments',
    exportSalesCRMDataV1: '/v1/bucket/jobs_export',
    updateSalesCRMStatusV1: '/v1/crmsale/activedeactivecrm',
    upgradeSalesCRMPlanV1: '/v1/crmsale/crmplanaddupgrade',
    getCurrentActivePlanV1: '/v1/crmsale/getplanactive',
    deleteSalesCommentV1: '/v1/crmsale/deletecomment',
    importSalesCRMDataV1: '/v1/bucket/jobs_import',
    addUpdateCustomeFieldV1: '/v1/crmsale/addupdatecustomefield',
    deleteAdditionalFieldV1: '/v1/crmsale/deletecustomfield',
    saveAdditionalFieldV1: '/v1/crmsale/saveupdatecustomfildjob',
    getAdditionalFieldV1: '/v1/crmsale/getcustomefields',

    //Leaderboard
    getLearderBoardListV1: '/v1/leaderboard/leaaderboard_list_by_office',
    getLearderBoardListByUserV1: '/v1/leaderboard/leaaderboard_list_by_user',

    // Admin report
    getAdminReportPersonalSummary: '/reports/personnel_summary',
    getAdminSnapshotSummary: '/reports/company_snapshot_new',
    getAdminReportData: '/reports/company_report_new',
    getAdminGraphData: '/reports/company_graph_new',
    getProjectedData: '/reports/company_projected_payouts',
}

export default EndPoints
