import {useLocation, useNavigate} from 'react-router-dom'
import useSequifiConfig from '../../../../sequiConfig/useSequifiConfig'
import {fontsFamily} from '../../../../assets/fonts/fonts'
import {
    formattedNumberFields,
    sendDataToReducer,
    TABLE_BORDER,
} from '../../../../helpers/CommonHelpers'
import {CustomSortSvg} from '../../../../_metronic/helpers/components/CustomSortSVG'
import {getValidDate} from '../../../../constants/constants'
import CustomButton2, {
    BUTTON_SIZE_STYLE,
    BUTTON_TYPE,
} from '../../../../customComponents/customButtton/CustomButton2'
import Pagination from '../../admin/sequidocs/component/Pagination'
import CustomTableSkeleton from '../../../../customComponents/customSkeleton/CustomTableSkeleton'
import {useEffect, useState} from 'react'
import {getTenantm2CompletedService} from '../../../../services/Services'
import Show from '../../../../customComponents/show/Show'
import CustomCardSkeleton from '../../../../customComponents/customSkeleton/CustomCardSkeleton'
import {useDispatch} from 'react-redux'
import {SET_MULTITENANT_FILTER_DATA} from '../../../../redux/reducers/FilterReducer'

const CompanyM2Page = ({
    filter,
    pageM2Completed,
    limitM2Completed,
    setLimit,
    setPage,
    filterData,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const {basedOnCompanyType} = useSequifiConfig()
    const [loading, setLoading] = useState(false)
    const [m2CompletedData, setm2CompletedData] = useState([])
    const [sortingOrder, setSortingOrder] = useState(null)
    const [sortValue, setSortValue] = useState(null)

    const getCompanyM2 = () => {
        const body = {
            tenant_id: location?.state?.tid,
            filter: filter,
            page: pageM2Completed,
            perpage: Number(limitM2Completed),
        }
        setLoading(true)
        getTenantm2CompletedService(body)
            .then((res) => {
                setm2CompletedData(res)
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false)
            })
    }

    const onPress = (item) => {
        setSortValue(item)
        setSortingOrder(sortValue !== item ? 'asc' : sortingOrder === 'asc' ? 'desc' : 'asc')
        setPage(1)
    }

    useEffect(() => {
        getCompanyM2()
        sendDataToReducer(dispatch, SET_MULTITENANT_FILTER_DATA, {
            ...filterData,
            filter,
            page: pageM2Completed,
            limit: limitM2Completed,
        })
    }, [filter, pageM2Completed, limitM2Completed, sortingOrder, sortValue])
    return (
        <div className='' style={{fontFamily: fontsFamily.manrope, fontSize: '14px'}}>
            {/* <CustomLoader full visible={loading} /> */}

            {/* <div className='d-flex gap-5 align-items-center mb-5'>
                <div onClick={() => navigate(-1)}>
                    <KTSVG
                        path='/media/icons/duotune/art/back-square.svg'
                        className='cursor-pointer'
                        svgClassName='w-25px h-25px'
                    />
                </div>
                <div className='text-sqGray900  text-xl-bold'>
                    Flex Power | M2 Completed | May 2023
                </div>
            </div> */}
            <Show>
                <Show.When isVisible={loading}>
                    <CustomCardSkeleton col='col-sm-2' />
                </Show.When>
                <Show.When isVisible={!loading}>
                    <div className='container mb-6'>
                        <div className='row gap-6'>
                            <>
                                {/* Card 1 */}
                                <div
                                    className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200 p-2'
                                    style={{borderRadius: 10, height: '96px'}}
                                >
                                    <div className='card-body'>
                                        <div className='card-title text-sqGray900 text-xl-bold'>
                                            {m2CompletedData?.m2_total}
                                        </div>
                                        <div className='card-text text-sqGray800 text-sm-semibold'>
                                            M2 Completed
                                        </div>
                                    </div>
                                </div>
                                {/* Card 2 */}
                                <div
                                    className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200 p-2'
                                    style={{borderRadius: 10}}
                                >
                                    <div className='card-body'>
                                        <div className='card-title text-sqGray900 text-xl-bold'>
                                            {m2CompletedData?.kw_total}
                                        </div>
                                        <div className='card-text text-sqGray800 card-text text-sm-semibold'>
                                            Total KW
                                        </div>
                                    </div>
                                </div>
                                {/* Card 3 */}
                                <div
                                    className='col-sm-5 col-md-4 col-lg-3 col-xl-2 card bg-sqIndigo200 p-2'
                                    style={{borderRadius: 10}}
                                >
                                    <div className='card-body'>
                                        <div className='card-title text-sqGray900 text-xl-bold'>
                                            {formattedNumberFields(
                                                m2CompletedData?.total_price,
                                                '$'
                                            )}
                                        </div>
                                        <div className='card-text text-sqGray800 text-sm-semibold'>
                                            Total Price
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </Show.When>
            </Show>

            <div className='bg-cmwhite mt-6' style={{borderRadius: 10, verticalAlign: 'top'}}>
                <div className='table-responsive' style={{borderRadius: 10}}>
                    <table className='table'>
                        <thead className={TABLE_BORDER}>
                            <tr className='text-sqGray800 bg-sqGray200 text-sm-semibold text-nowrap'>
                                <th className='p-5 text-nowrap'>PID</th>
                                <th className='p-5 text-nowrap'>Customer</th>
                                <th className='p-5 text-nowrap'>State</th>
                                <th className='p-5 text-nowrap'>
                                    <div className='d-flex align-items-center'>
                                        {/* {basedOnCompanyType?.primaryUnitType} */}
                                        KW
                                        <CustomSortSvg
                                            id={'comapny_type_Sort_Icon'}
                                            sortingOrder={sortValue === 'kw' ? sortingOrder : null}
                                            onClick={() => onPress('kw')}
                                        />
                                    </div>
                                </th>
                                <th className='p-5 text-nowrap'>M2 Date</th>
                                <th className='p-5 text-nowrap'>Price</th>
                                <th className='p-5 text-nowrap'>
                                    <div className='d-flex align-items-center'>
                                        Price Billed
                                        <CustomSortSvg
                                            id={'comapny_billing_Sort_Icon'}
                                            sortingOrder={
                                                sortValue === 'billed_price' ? sortingOrder : null
                                            }
                                            onClick={() => onPress('billed_price')}
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className={TABLE_BORDER}>
                            {loading ? (
                                <CustomTableSkeleton coloums={7} />
                            ) : m2CompletedData?.data?.data?.length > 0 ? (
                                <>
                                    {m2CompletedData?.data?.data?.map((item) => (
                                        <tr key={0} className='text-sqGray800 stripRow'>
                                            <td className='p-5 text-nowrap text-xs-medium'>
                                                {item?.pid ?? '-'}
                                            </td>
                                            <td className='p-5 text-nowrap text-xs-medium'>
                                                {item?.customer_name}
                                            </td>
                                            <td className='p-5 text-nowrap text-xs-medium'>
                                                {item?.customer_state}
                                            </td>
                                            <td className='p-5 text-nowrap text-xs-medium'>
                                                {'4.23' ?? '-'}
                                            </td>

                                            <td className='p-5 text-nowrap text-xs-medium'>
                                                {getValidDate(item?.m2_date)}
                                            </td>
                                            <td className='p-5 text-nowrap text-xs-medium'>
                                                $ {item?.price}/
                                                {basedOnCompanyType?.secondaryUnitType}
                                            </td>
                                            <td className='p-5 text-nowrap text-xs-medium'>
                                                {formattedNumberFields(item?.billed_price, '$')}
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td
                                        colSpan={12}
                                        style={{
                                            textAlign: 'center',
                                            fontFamily: 'Manrope',
                                            fontWeight: '500',
                                            fontSize: 14,
                                            paddingTop: 20,
                                            paddingBottom: 20,
                                        }}
                                    >
                                        No data found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='pb-5 pe-5'>
                    <Pagination
                        tableName={'Company_Unique_id'}
                        page={pageM2Completed}
                        totalPages={m2CompletedData?.data?.last_page}
                        limit={limitM2Completed}
                        setLimit={setLimit}
                        setPage={(changedPage) => setPage(changedPage)}
                    />
                </div>
            </div>

            {/* <div className='d-flex justify-content-center align-items-center mt-6'>
                <CustomButton2
                    buttonLabel='Load More'
                    buttonSize={BUTTON_SIZE_STYLE.lg}
                    buttonType={BUTTON_TYPE.primary}
                    onClick={() => navigate('/new-user')}
                />
            </div> */}
        </div>
    )
}

export default CompanyM2Page
