import {KTSVG} from '../../../_metronic/helpers'
import {fontsFamily} from '../../../assets/fonts/fonts'
import {BUTTON_SIZE} from '../../../customComponents/customButtton/CustomButton'
import {useNavigate} from 'react-router'
import {getUserDataSelector} from '../../../redux/selectors/AuthSelectors'
import {useSelector} from 'react-redux'
import {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
    formattedNumberFields,
    getErrorMessageFromResponse,
    sendDataToReducer,
} from '../../../helpers/CommonHelpers'
import Chart from 'react-apexcharts'
import CustomDropdown from '../../../customComponents/customInputs/customDropdown/CustomDropdown'
import CustomButton2, {BUTTON_TYPE} from '../../../customComponents/customButtton/CustomButton2'
import {
    getValidDate,
    GLOBAL_SEARCH_TYPE,
    MULTITENANT_DASHBOARD_CHART,
} from '../../../constants/constants'
import {
    dashboardGraphService,
    getClientsAndActiveUsersDashboardService,
    getTotalEarningService,
} from '../../../services/Services'
import CustomToast from '../../../customComponents/customToast/CustomToast'
import CustomCardSkeleton from '../../../customComponents/customSkeleton/CustomCardSkeleton'
import Show from '../../../customComponents/show/Show'
import {getMultitenantDashboardFilterDataSelector} from '../../../redux/selectors/FilterSelectors'
import {SET_MULTITENANT_DASHBOARD_FILTER_DATA} from '../../../redux/reducers/FilterReducer'

const tempData = {
    categories: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    series: [
        {
            name: 'PayrollProcessing',
            data: [15, 66, 70, 50, 40, 10, 45, 90, 20, 30, 40, 50],
        },
        {
            name: 'BackgroundChecks',
            data: [30, 10, 50, 20, 40, 65, 58, 50, 60, 30, 60, 10],
        },
        {
            name: 'BaseProduct',
            data: [5, 60, 30, 60, 30, 55, 35, 70, 20, 50, 10, 30],
        },
    ],
}
const MultiTenantDashboard = () => {
    const naviagte = useNavigate()
    const dispatch = useDispatch()

    const filterDataDashboard = useSelector(getMultitenantDashboardFilterDataSelector)

    const userData = useSelector(getUserDataSelector)
    const [dashboardFirstCard, setDashboardFirstCard] = useState()
    const [dashboardSecondCard, setDashboardSecondCard] = useState()
    const [chartSeriesData, setChartSeriesData] = useState()
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(filterDataDashboard?.filter)

    const onChangeFilter = (data) => {
        setFilter(data)
    }
    const getTotalEarnings = () => {
        const body = {
            filter: filter,
        }
        setLoading(true)
        getTotalEarningService(body)
            .then((res) => {
                setDashboardFirstCard(res?.data)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }
    const getdashboardSecondCard = () => {
        setLoading(true)
        getClientsAndActiveUsersDashboardService()
            .then((res) => {
                setDashboardSecondCard(res?.data || {})
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }

    const dashboardChart = () => {
        const body = {
            filter: '',
        }
        dashboardGraphService(body)
            .then((res) => {
                setChartSeriesData(res)
            })
            .catch((e) => {
                CustomToast.error(getErrorMessageFromResponse(e))
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getTotalEarnings()
        getdashboardSecondCard()
        dashboardChart()
        sendDataToReducer(dispatch, SET_MULTITENANT_DASHBOARD_FILTER_DATA, {
            ...filterDataDashboard,
            filter,
        })
    }, [filter])
    return (
        <div className='' style={{fontFamily: fontsFamily.manrope}}>
            {/* Body */}
            <div className='container text-cmBlack'>
                {/* header */}
                <div>
                    <div className='text-dmd-bold text-sqGray900'>Welcome to Sequifi!</div>
                    <div className='text-sqGray900' style={{fontSize: 15.5, fontWeight: 600}}>
                        <span className='text-sqGray600'>Last Login:</span>
                        {getValidDate(userData?.lastLogin, 'MM/DD/YYYY [at] hh:mm a') ?? '-'}
                    </div>
                </div>

                <div className='my-10 row gap-xxl-10 gap-20'>
                    {/* <div className='d-flex flex-center gap-20 my-10'> */}
                    <div className='col d-flex justify-content-end px-0'>
                        <CustomButton2
                            buttonLabel='Add a new Client'
                            onClick={() => naviagte('/new-user')}
                            buttonSize={BUTTON_SIZE.xl}
                            buttonType={BUTTON_TYPE.primary}
                            // width={'225px'}
                            className='px-lg-12 px-sm-4'
                        />
                    </div>
                    <div className='col d-flex justify-content-start px-0'>
                        <CustomButton2
                            buttonLabel='Client Directory'
                            onClick={() => naviagte('/client-directory')}
                            buttonSize={BUTTON_SIZE.xl}
                            buttonType={BUTTON_TYPE.primary}
                            // width={'225px'}
                            className='px-lg-13 px-sm-4'
                        />
                    </div>
                </div>
                <div className='row gap-xxl-10 gap-5 mb-10'>
                    <div className='col-lg'>
                        {loading ? (
                            <CustomCardSkeleton
                                count={1}
                                // col='col-sm-2'
                                // height='40px'
                                // borderRadius={5}
                            />
                        ) : (
                            <CommonTopCards
                                heading1={formattedNumberFields(dashboardFirstCard?.total_earning)}
                                content1={'Total Earnings'}
                                heading2={formattedNumberFields(
                                    dashboardFirstCard?.this_month_total_earning
                                )}
                                content2={'Earnings this month'}
                            />
                        )}
                    </div>
                    <div className='col-lg'>
                        {loading ? (
                            <CustomCardSkeleton
                                count={1}
                                // col='col-sm-12'
                                // height='125px'
                                // borderRadius={5}
                            />
                        ) : (
                            <CommonTopCardsSecond
                                heading1={
                                    dashboardSecondCard?.this_month_tenantCount
                                        ? dashboardSecondCard?.this_month_tenantCount
                                        : 0
                                }
                                content1={'New Clients'}
                                content4={'This Month'}
                                heading2={
                                    dashboardSecondCard?.tenantCount
                                        ? dashboardSecondCard?.tenantCount
                                        : 0
                                }
                                content2={'Total Clients'}
                                heading3={
                                    dashboardSecondCard?.total_active_users
                                        ? dashboardSecondCard?.total_active_users
                                        : 0
                                }
                                content3={'ActiveUsers'}
                            />
                        )}
                    </div>
                </div>
                {/* Charts */}
                <div className='row gap-10 '>
                    <div className='col-lg'>
                        <Show>
                            <Show.When isVisible={loading}>
                                <CustomCardSkeleton
                                    count={1}
                                    cardWidth='w-100'
                                    containerWidth='col-12 ps-0 pe-0 mx-auto'
                                    col='px-0'
                                    height='220px'
                                />
                            </Show.When>
                            <Show.When isVisible={!loading}>
                                <>
                                    {chartSeriesData && (
                                        <EarningChart
                                            chartSeriesData={chartSeriesData}
                                            loading={loading}
                                            filter={filter}
                                            onChangeFilter={onChangeFilter}
                                        />
                                    )}
                                </>
                            </Show.When>
                        </Show>
                    </div>
                    {/* <div className='col-xl-3 col-lg-4 d-flex flex-wrap gap-10'>
                        <div className='w-100'>
                            <CustomSideCard
                                heading={'6'}
                                subHeading={'Clients Onboarded'}
                                icon={'UsersThree'}
                                iconBackground={'#EAE6FF'}
                            />
                        </div>
                        <div className='w-100'>
                            <CustomSideCard
                                heading={'60K'}
                                subHeading={'Earnings'}
                                icon={'greenDollar'}
                                iconBackground={'rgba(93, 184, 135, 0.2)'}
                            />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default MultiTenantDashboard

// Inside components
const CommonTopCards = ({background, className, heading1, heading2, content1, content2}) => (
    <div className={`card card-flush ${className} px-xl-20 px-10 py-xl-10 py-5 shadow-sm`}>
        <div className='d-sm-flex justify-content-between'>
            <div>
                <div className='text-dsm-bold text-sqGray900 text-center'>{heading1}</div>
                <div className='text-lg-medium text-cmGrey500 text-center'>{content1}</div>
            </div>
            <div className='vr d-sm-block d-none my-3' />
            <hr className=' d-sm-none d-block' />
            <div>
                <div className='text-dsm-bold text-sqGray900 text-center'>{heading2}</div>
                <div className='text-lg-medium text-cmGrey500 text-center'>{content2}</div>
            </div>
        </div>
    </div>
)

const CommonTopCardsSecond = ({
    background,
    className,
    heading1,
    heading2,
    content1,
    content2,
    heading3,
    content3,
    content4,
}) => (
    <div className={`card card-flush ${className} px-xl-10 px-10 py-xl-8 py-5 shadow-sm`}>
        <div className='d-sm-flex justify-content-between'>
            <div>
                <div className='text-dsm-bold text-sqGray900 text-center'>{heading1}</div>
                <div
                    className='text-lg-medium text-cmGrey500 text-center'
                    style={{lineHeight: '12px'}}
                >
                    {content1}
                </div>
                <div className='text-lg-medium text-cmGrey500 text-center'>{content4}</div>
            </div>
            <div className='vr d-sm-block d-none mt-3 mb-6' />
            <hr className='d-sm-none d-block' />
            <div>
                <div className='text-dsm-bold text-sqGray900 text-center'>{heading2}</div>
                <div className='text-lg-medium text-cmGrey500 text-center'>{content2}</div>
            </div>
            <div className='vr d-sm-block d-none mt-3 mb-6' />
            <hr className=' d-sm-none d-block' />
            <div>
                <div className='text-dsm-bold text-sqGray900 text-center'>{heading3}</div>
                <div className='text-lg-medium text-cmGrey500 text-center'>{content3}</div>
            </div>
        </div>
    </div>
)
const EarningChart = ({chartSeriesData, filter, onChangeFilter, loading}) => {
    const axisTextStyle = {
        fontsFamily: fontsFamily.manrope,
        colors: '#979699',
        fontSize: '13.5px',
        fontWeight: '600',
        lineHeight: '18.26px',
    }

    const getMaxY = (data) => {
        let max = 0
        data?.forEach((element) => {
            const maxEle = Math.max(...element?.data)
            max = maxEle > max ? maxEle : max
        })
        const roundMax = Math.ceil(max / 50) * 50
        return roundMax
    }
    const chartOptions = {
        chart: {
            type: 'area',
            toolbar: {
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
        },
        grid: {
            borderColor: '#F2EDEE',
            padding: {
                left: 15,
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        xaxis: {
            categories: chartSeriesData?.categories,
            labels: {
                style: axisTextStyle,
            },
        },
        yaxis: {
            labels: {
                style: axisTextStyle,
                offsetX: -5,
                formatter: (value) => {
                    const roundedValue = Math?.max(Math?.round(value / 50) * 50, 10)
                    return `${roundedValue}M`
                },
            },
            min: 10,
            max: getMaxY(chartSeriesData?.series),
            tickAmount: getMaxY(chartSeriesData?.series) / 50,
            tickPlacement: 'on',
        },
        stroke: {
            curve: 'smooth',
        },
        fill: {
            type: 'solid',
            opacity: 0,
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: ['#12B76A', '#FEC84B', '#6078EC'],
        legend: {
            show: false,
        },
        tooltip: {
            shared: true,
            intersect: false,
            x: {
                formatter: (value) => {
                    return ''
                },
            },
            custom: ({series, seriesIndex, dataPointIndex, w}) => {
                const xValue = w?.globals?.categoryLabels[dataPointIndex]
                const yValues = series?.map((s) => s[dataPointIndex])

                return `
            <div class="bg-white border border-secondary rounded-3 p-3 shadow-sm" style="width: 200px;">
                <div class="fs-5 fw-bold mb-2 text-dark">
                    ${xValue}
                </div>
                ${series
                    ?.map(
                        (s, i) => `
                    <div class="d-flex align-items-center mb-1">
                        <span class="d-inline-block me-2" style="width: 10px; height: 10px; background-color: ${w?.config?.colors[i]}; border-radius: 50%;"></span>
                        <span class="text-muted"><strong>${w?.config?.series[i]?.name}:</strong> <span class="fw-bold text-dark">${yValues[i]}</span></span>
                    </div>
                `
                    )
                    ?.join('')}
            </div>
        `
            },
        },
    }

    const chartSeries = chartSeriesData?.series?.map((seriesItem) => ({
        name: seriesItem?.name,
        data: seriesItem?.data,
    }))

    return (
        <div
            className='bg-cmwhite p-4 pb-3 shadow-sm'
            style={{fontFamily: fontsFamily.manrope, borderRadius: 10}}
        >
            <div className='d-flex flex-column flex-sm-row justify-content-between gap-3 gap-sm-5 mb-4'>
                <div>
                    <div className='text-sqGray900 text-dsm-bold'>
                        {formattedNumberFields(20833.89)}
                    </div>
                    <div className='text-md-medium text-cmBlue-Crayola'>Total Earnings</div>
                </div>
                <div className='d-flex flex-wrap gap-3 text-cmGrey600'>
                    <div className='d-flex align-items-center gap-2'>
                        <span
                            className='badge text-xs badge-cmSuccess badge-circle'
                            style={{width: '13px', height: '13px'}}
                        />
                        <span>Background Checks</span>
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                        <span
                            className='badge badge-cmYellow badge-circle'
                            style={{width: '13px', height: '13px'}}
                        />
                        <span>Payroll Processing</span>
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                        <span
                            className='badge  badge-cmBlue-Crayola badge-circle'
                            style={{width: '13px', height: '13px'}}
                        />
                        <span>Base Product</span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <CustomDropdown
                            id={'Chart_Center_select'}
                            value={filter}
                            showClear={false}
                            searching={false}
                            valueKey='value'
                            displayKey='name'
                            onChange={(e) => onChangeFilter(e.target.value)}
                            options={MULTITENANT_DASHBOARD_CHART}
                        />
                    </div>
                </div>
            </div>
            {/* <div className='overflow-auto'> */}
            <div className='scroll-bar'>
                <div className='chart-container' style={{width: '100%', minWidth: '600px'}}>
                    <Chart
                        options={chartOptions && chartOptions}
                        series={chartSeries && chartSeries}
                        type='area'
                        height={250}
                    />
                </div>
            </div>
        </div>
    )
}

const CustomSideCard = ({heading, subHeading, icon, iconBackground}) => {
    return (
        <div
            className='bg-cmwhite  p-10 shadow-sm'
            style={{fontFamily: fontsFamily.manrope, borderRadius: 10}}
        >
            <div className='d-flex flex-start gap-5'>
                <div
                    className='badge badge-circle w-65px h-65px'
                    style={{backgroundColor: iconBackground}}
                >
                    <KTSVG
                        path={`/media/icons/duotune/art/${icon}.svg`}
                        svgClassName='w-35px h-35px cursor-pointer'
                    />
                </div>
                <div>
                    <div className='text-cmGrey900' style={{fontSize: 28, fontWeight: 600}}>
                        {heading}
                    </div>
                    <div
                        className='text-cmBlue-Crayola mb-3'
                        style={{fontSize: 15.4, fontWeight: 500, lineHeight: '21px'}}
                    >
                        {subHeading}
                    </div>
                    <div>
                        {/* <CustomDropdown className='p-0' placeholder='6 months' /> */}
                        <CustomDropdown
                            id={'Chart_Center_select'}
                            // value={filter}
                            showClear={false}
                            searching={false}
                            valueKey='value'
                            displayKey='name'
                            // onChange={(e) => onChangeFilter(e.target.value)}
                            options={MULTITENANT_DASHBOARD_CHART}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
