import {createSlice} from '@reduxjs/toolkit'
import {getValidDate} from '../../constants/constants'

const DEFAULT_PAGE_LIMIT = 10

export const FILTER_INITIAL_DATA = {
    dashboard: {
        standard: {
            sales_report: 'last_12_months',
            office_performance: 'last_12_months',
        },
        admin: {
            payroll_year_filter: 'last_12_months',
            office_sales_year_filter: 'last_12_months',
            office_sales_location_filter: 'all',
        },
    },
    support_history: {
        search: '',
        status: '',
        priority: '',
        filter: {
            module: '',
            created_by: '',
        },
        limit: DEFAULT_PAGE_LIMIT,
        page: 1,
    },
    location: {
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
        filter: {
            location: '',
            state: '',
        },
    },
    costcenter: {
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
        status: 'all',
    },
    alert: {
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
        alertType: 'sales',
        limitAll: DEFAULT_PAGE_LIMIT,
        activePageForALL: 1,
        globalFilterType: 'payroll',
        searchAll: '',
        quickFilter: '',
        toggleAllSearch: false,
        pay_frequency: null,
        pay_period: null,
    },
    department: {
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
    },
    particular_department: {
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
    },
    particular_position: {
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
    },
    position: {
        filter: {
            pay_frequency_filter: '',
            department: '',
            override_settelement: '',
            permission_group: '',
        },
        page: 1,
        search: '',
    },
    mysales: {
        mysales: {
            year: 'last_12_months',
            search: '',
            m1: '',
            m2: '',
            closed: '',
            page: 1,
            limit: DEFAULT_PAGE_LIMIT,
        },
        override: {
            year: 'last_12_months',
            search: '',
            type: '',
            isProjection: false,
            limit: DEFAULT_PAGE_LIMIT,
        },
    },
    hiring: {
        hiring_progress: {
            office: '',
            start_date: null,
            end_date: null,
            page: 1,
            limit: DEFAULT_PAGE_LIMIT,
        },
        onboarding: {
            office: '',
            filter: '',
            filters: {
                status_filter: '',
                position_filter: '',
                manager_filter: '',
                other_status_filter: '',
            },
            page: 1,
            limit: DEFAULT_PAGE_LIMIT,
        },
        leads: {
            search: '',
            filter: {
                status_filter: '',
                home_state_filter: '',
                recruter_filter: '',
                sources: '',
                reporting_manager: '',
            },
            limit: DEFAULT_PAGE_LIMIT,
            page: 1,
        },
        pipeLine: {
            search: '',
            filter: {
                office: '',
                position: '',
                days_in_status: '',
            },
        },
    },
    refferal: {
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
    },
    management: {
        employees: {
            office: '',
            search: '',
            page: 1,
            limit: DEFAULT_PAGE_LIMIT,
        },
        teams: {
            office: '',
            search: '',
        },
    },
    payroll: {
        payment_request: {
            search: '',
            typeFilter: '',
            requestType: 'PaymentRequest',
            page: 1,
            limit: DEFAULT_PAGE_LIMIT,
        },
        onetime_payment_history: {
            status: 'all_status',
            duration: 'last_12_months',
            type: '',
            search: '',
            page: 1,
            limit: DEFAULT_PAGE_LIMIT,
            start_date: null,
            end_date: null,
        },
        recon: {
            limit: DEFAULT_PAGE_LIMIT,
            draftLimit: DEFAULT_PAGE_LIMIT,
        },
        runPayroll: {
            filter: {
                position_filter: '',
                netpay_filter: '',
                commission_filter: '',
            },
            limit: DEFAULT_PAGE_LIMIT,
        },
    },
    reports: {
        standard: {
            office: {
                location: '',
                repId: '',
                filter: 'last_12_months',
                search: '',
                page: 1,
                limit: DEFAULT_PAGE_LIMIT,
            },
            sales: {
                location: '',
                repId: '',
                year: 'last_12_months',
                filter: {
                    status_filter: '',
                    installer_filter: '',
                    date_filter: '',
                },
                search: '',
                page: 1,
                limit: DEFAULT_PAGE_LIMIT,
            },
            pastpaystubs: {
                year: getValidDate(new Date(), 'YYYY-MM-DD'),
                payType: 'paystub',
                page: 1,
                limit: DEFAULT_PAGE_LIMIT,
            },
        },
        admin: {
            company: {
                office: '',
                year: 'last_12_months',
                overview: 'all',
            },
            sales: {
                office: 'all',
                year: 'last_12_months',
                search: '',
                filter: {
                    date_filter: '',
                },
                page: 1,
                limit: DEFAULT_PAGE_LIMIT,
            },
            cost: {
                location: '',
                duration: 'last_12_months',
                search: '',
                filter: {
                    employee_id: '',
                    cost_tracking_id: '',
                    approved_by: '',
                    requested_on: '',
                },
                page: 1,
                limit: DEFAULT_PAGE_LIMIT,
                corporatePage: 1,
                corporateLimit: DEFAULT_PAGE_LIMIT,
            },
            payroll: {
                year: getValidDate(new Date(), 'YYYY'),
                month: 'all',
                pay_frequency: '',
            },
            particular_pay_period: {
                search: '',
                limit: DEFAULT_PAGE_LIMIT,
            },
            particular_recon_period: {
                search: '',
                page: 1,
                limit: DEFAULT_PAGE_LIMIT,
            },
            reconciliation: {
                year: getValidDate(new Date(), 'YYYY'),
                search: '',
                page: 1,
                limit: DEFAULT_PAGE_LIMIT,
            },
            pendingInstall: {
                location: 'all',
                duration: 'last_12_months',
                search: '',
                filter: {
                    filter_install: '',
                    filter_closer_setter: '',
                    filter_age_of_account: '',
                    filter_show_only_account: '',
                },
                page: 1,
                limit: DEFAULT_PAGE_LIMIT,
            },
            clawback: {
                location: '',
                duration: 'last_12_months',
                search: '',
                page: 1,
                limit: DEFAULT_PAGE_LIMIT,
                filters: {
                    state_code: '',
                    sales_rep_id: '',
                    clawback_date: '',
                    last_payment_date: '',
                },
            },
        },
    },
    sequicrm: {
        search: '',
        bucket: '',
        state: '',
        office: 'all',
        timer: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
    },
    permission: {
        group: {
            search: '',
        },
        particular_group: {
            limit: DEFAULT_PAGE_LIMIT,
            page: 1,
        },
        policies: {
            search: '',
        },
    },
    request_approval: {
        my_request: {
            search: '',
            filter: {
                status: '',
                type: '',
            },
            page: 1,
            limit: DEFAULT_PAGE_LIMIT,
        },
        approval: {
            search: '',
            filter: {
                status: '',
                type: '',
            },
            apiType: 'approval',
            page: 1,
            limit: DEFAULT_PAGE_LIMIT,
        },
    },
    userManagement: {
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
        filters: {
            position_filter: '',
            office_filter: '',
            status_filter: '',
            showAdmin_filter: '',
        },
    },
    leaderBoard: {
        view: 'office',
        office_id: '',
        filter: 'last_12_months',
        start_date: null,
        end_date: null,
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
    },
    multitenant: {
        filter: 'this_month',
        search: '',
        page: 1,
        limit: DEFAULT_PAGE_LIMIT,
    },
    multitenantDashboard: {
        filter: 'six_month',
    },
}
export const filterInitialState = {
    location: FILTER_INITIAL_DATA.location,
    costcenter: FILTER_INITIAL_DATA.costcenter,
    alert: FILTER_INITIAL_DATA.alert,
    support_history: FILTER_INITIAL_DATA.support_history,
    permission: FILTER_INITIAL_DATA.permission,
    position: FILTER_INITIAL_DATA.position,
    particular_position: FILTER_INITIAL_DATA.particular_position,
    particular_department: FILTER_INITIAL_DATA.particular_department,
    dashboard: FILTER_INITIAL_DATA.dashboard,
    department: FILTER_INITIAL_DATA.department,
    mysales: FILTER_INITIAL_DATA.mysales,
    hiring: FILTER_INITIAL_DATA.hiring,
    refferal: FILTER_INITIAL_DATA.refferal,
    management: FILTER_INITIAL_DATA.management,
    standard: FILTER_INITIAL_DATA.reports.standard,
    request_approval: FILTER_INITIAL_DATA.request_approval,
    company: FILTER_INITIAL_DATA.reports.admin.company,
    sales: FILTER_INITIAL_DATA.reports.admin.sales,
    cost: FILTER_INITIAL_DATA.reports.admin.cost,
    payroll: FILTER_INITIAL_DATA.reports.admin.payroll,
    reconciliation: FILTER_INITIAL_DATA.reports.admin.reconciliation,
    clawback: FILTER_INITIAL_DATA.reports.admin.clawback,
    pendingInstall: FILTER_INITIAL_DATA.reports.admin.pendingInstall,
    paymentRequest: FILTER_INITIAL_DATA.payroll.payment_request,
    oneTimePaymentHistory: FILTER_INITIAL_DATA.payroll.onetime_payment_history,
    payrollReconciliation: FILTER_INITIAL_DATA.payroll.recon,
    runPayroll: FILTER_INITIAL_DATA.payroll.runPayroll,
    particularPayPeriod: FILTER_INITIAL_DATA.reports.admin.particular_pay_period,
    particularReconPeriod: FILTER_INITIAL_DATA.reports.admin.particular_recon_period,
    userManagement: FILTER_INITIAL_DATA.userManagement,
    sequicrm: FILTER_INITIAL_DATA.sequicrm,
    leaderBoard: FILTER_INITIAL_DATA.leaderBoard,
    multitenant: FILTER_INITIAL_DATA.multitenant,
    multitenantDashboard: FILTER_INITIAL_DATA.multitenantDashboard,
}
const FilterSlice = createSlice({
    name: 'filter',
    initialState: filterInitialState,
    reducers: {
        SET_LOCATION_FILTER_DATA: (state, action) => {
            state.location = {...state.location, ...action.payload}
        },
        SET_COSTCENTER_FILTER_DATA: (state, action) => {
            state.costcenter = {...state.costcenter, ...action.payload}
        },
        SET_ALERT_FILTER_DATA: (state, action) => {
            state.alert = {...state.alert, ...action.payload}
        },
        SET_PERMISSION_FILTER_DATA: (state, action) => {
            state.permission = {...state.permission, ...action.payload}
        },
        SET_DASHBOARD_FILTER_DATA: (state, action) => {
            state.dashboard = {...state.dashboard, ...action.payload}
        },
        SET_PAR_DEPARTMEMT_FILTER_DATA: (state, action) => {
            state.particular_department = {...state.particular_department, ...action.payload}
        },
        SET_PAR_POSITION_FILTER_DATA: (state, action) => {
            state.particular_position = {...state.particular_position, ...action.payload}
        },
        SET_SUPPORT_HISTORY_FILTER_DATA: (state, action) => {
            state.support_history = {...state.support_history, ...action.payload}
        },
        SET_DEPARTMENT_FILTER_DATA: (state, action) => {
            state.department = {...state.department, ...action.payload}
        },
        SET_POSITION_FILTER_DATA: (state, action) => {
            state.position = {...state.position, ...action.payload}
        },
        SET_HIRING_FILTER_DATA: (state, action) => {
            state.hiring = {...state.hiring, ...action.payload}
        },

        SET_MYSALES_FILTER_DATA: (state, action) => {
            state.mysales = {...state.mysales, ...action.payload}
        },
        SET_REFERRAL_FILTER_DATA: (state, action) => {
            state.refferal = {...state.refferal, ...action.payload}
        },
        SET_MANAGEMENT_FILTER_DATA: (state, action) => {
            state.management = {...state.management, ...action.payload}
        },
        SET_STANDARD_REPORTS_FILTER_DATA: (state, action) => {
            state.standard = {...state.standard, ...action.payload}
        },
        SET_ADMIN_COMPANY_FILTER_DATA: (state, action) => {
            state.company = {...state.company, ...action.payload}
        },
        SET_ADMIN_SALES_FILTER_DATA: (state, action) => {
            state.sales = {...state.sales, ...action.payload}
        },
        SET_ADMIN_COST_FILTER_DATA: (state, action) => {
            state.cost = {...state.cost, ...action.payload}
        },
        SET_ADMIN_PAYROLL_FILTER_DATA: (state, action) => {
            state.payroll = {...state.payroll, ...action.payload}
        },
        SET_ADMIN_RECON_FILTER_DATA: (state, action) => {
            state.reconciliation = {...state.reconciliation, ...action.payload}
        },
        SET_ADMIN_CLAWBACK_FILTER_DATA: (state, action) => {
            state.clawback = {...state.clawback, ...action.payload}
        },
        SET_ADMIN_PENDINGINSTALL_FILTER_DATA: (state, action) => {
            state.pendingInstall = {...state.pendingInstall, ...action.payload}
        },
        SET_REQAPPROVAL_FILTER_DATA: (state, action) => {
            state.request_approval = {...state.request_approval, ...action.payload}
        },
        SET_PAYMENT_REQUEST_FILTER_DATA: (state, action) => {
            state.paymentRequest = {...state.paymentRequest, ...action.payload}
        },
        SET_ONETIME_PAYMENT_HISTORY_FILTER_DATA: (state, action) => {
            state.oneTimePaymentHistory = {...state.oneTimePaymentHistory, ...action.payload}
        },
        SET_PAYROLL_RECON_FILTER_DATA: (state, action) => {
            state.payrollReconciliation = {...state.payrollReconciliation, ...action.payload}
        },
        SET_RUNPAYROLL_FILTER_DATA: (state, action) => {
            state.runPayroll = {...state.runPayroll, ...action.payload}
        },
        SET_PARTICULAR_RECON_FILTER_DATA: (state, action) => {
            state.particularReconPeriod = {...state.particularReconPeriod, ...action.payload}
        },
        SET_PARTICULAR_PAYROLL_FILTER_DATA: (state, action) => {
            state.particularPayPeriod = {...state.particularPayPeriod, ...action.payload}
        },
        SET_USER_MANAGEMENT_FILTER_DATA: (state, action) => {
            state.userManagement = {...state.userManagement, ...action.payload}
        },
        SET_SEQUICRM_FILTER_DATA: (state, action) => {
            state.sequicrm = {...state.sequicrm, ...action.payload}
        },
        SET_LEADERBOARD_FILTER_DATA: (state, action) => {
            state.leaderBoard = {...state.leaderBoard, ...action.payload}
        },
        SET_MULTITENANT_FILTER_DATA: (state, action) => {
            state.multitenant = {...state.multitenant, ...action.payload}
        },
        SET_MULTITENANT_DASHBOARD_FILTER_DATA: (state, action) => {
            state.multitenantDashboard = {...state.multitenantDashboard, ...action.payload}
        },
    },
})

const {actions, reducer: FilterReducer} = FilterSlice

export const {
    SET_LOCATION_FILTER_DATA,
    SET_COSTCENTER_FILTER_DATA,
    SET_ALERT_FILTER_DATA,
    SET_DASHBOARD_FILTER_DATA,
    SET_PERMISSION_FILTER_DATA,
    SET_DEPARTMENT_FILTER_DATA,
    SET_PAR_DEPARTMEMT_FILTER_DATA,
    SET_PAR_POSITION_FILTER_DATA,
    SET_POSITION_FILTER_DATA,
    SET_MYSALES_FILTER_DATA,
    SET_SUPPORT_HISTORY_FILTER_DATA,
    SET_REFERRAL_FILTER_DATA,
    SET_HIRING_FILTER_DATA,
    SET_MANAGEMENT_FILTER_DATA,
    SET_REQAPPROVAL_FILTER_DATA,
    SET_STANDARD_REPORTS_FILTER_DATA,
    SET_ADMIN_COMPANY_FILTER_DATA,
    SET_ADMIN_SALES_FILTER_DATA,
    SET_ADMIN_COST_FILTER_DATA,
    SET_ADMIN_PAYROLL_FILTER_DATA,
    SET_ADMIN_RECON_FILTER_DATA,
    SET_ADMIN_CLAWBACK_FILTER_DATA,
    SET_ADMIN_PENDINGINSTALL_FILTER_DATA,
    SET_PAYMENT_REQUEST_FILTER_DATA,
    SET_ONETIME_PAYMENT_HISTORY_FILTER_DATA,
    SET_PAYROLL_RECON_FILTER_DATA,
    SET_PARTICULAR_PAYROLL_FILTER_DATA,
    SET_PARTICULAR_RECON_FILTER_DATA,
    SET_RUNPAYROLL_FILTER_DATA,
    SET_USER_MANAGEMENT_FILTER_DATA,
    SET_SEQUICRM_FILTER_DATA,
    SET_LEADERBOARD_FILTER_DATA,
    SET_MULTITENANT_FILTER_DATA,
    SET_MULTITENANT_DASHBOARD_FILTER_DATA,
} = actions

export default FilterReducer
